import React from "react";
import img from "../../assets/images/productimg6.webp";
import { motion } from "framer-motion";

const Cover = () => {
  return (
    <div className="mx-auto max-w-screen-2xl sm:pb-24 flex flex-col-reverse sm:flex-row overflow-hidden">
      <div className="sm:w-[50%] relative">
        <motion.img
          initial={{ x: -200, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          src={img}
          alt="Product"
        />
        <div className="hidden sm:block absolute bottom-0 w-[150px] h-[220px] bg-[#ff4a23] skew-x-[16deg] -left-20"></div>
      </div>
      <div className="sm:w-[50%]">
        <div className="sm:h-[70%] py-10 skew-x-[16deg] -mr-20 sm:-mr-10 mb-6 bg-gradient-to-r from-[#ff4a23]/75 via-[#ff4a23]/90 to-[#ff4a23] flex flex-col justify-center px-10 sm:px-20">
          <motion.p
            initial={{ x: 200, opacity: 0, skewX: -16 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="poppins-regular -skew-x-[16deg] text-sm text-white sm:text-xl"
          >
            When it Comes to tanks <br /> We Have You
          </motion.p>
          <motion.p
            initial={{ x: 200, opacity: 0, skewX: -16 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            className="kalnia-regular -skew-x-[16deg] text-white text-lg sm:text-5xl"
          >
            # Covered
          </motion.p>
        </div>
        {/* <div className="flex flex-wrap gap-4 sm:gap-10 w-full px-2 justify-center pb-4 sm:px-8"> */}
        {/*   <button className="px-3 sm:px-6 py-2 text-sm sm:text-base border border-[#ff4a23] rounded-3xl text-[#ff4a23] hover:bg-[#ff4a23] hover:text-[white]"> */}
        {/*     Cover Brochure */}
        {/*   </button> */}
        {/*   <button className="px-3 sm:px-6 py-2 text-sm sm:text-base border border-[#ff4a23] rounded-3xl text-[#ff4a23] hover:bg-[#ff4a23] hover:text-[white]"> */}
        {/*     Cover Date Sheet */}
        {/*   </button> */}
        {/*   <button className="px-3 sm:px-6 py-2 text-sm sm:text-base border border-[#ff4a23] rounded-3xl text-[#ff4a23] hover:bg-[#ff4a23] hover:text-[white]"> */}
        {/*     Cover References */}
        {/*   </button> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default Cover;
