import React from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "./common/PrimaryButton";
import logo from "../assets/images/logo2.webp";

type NavLinks = {
  name: string;
  path: string;
};

const leftNavLinks: NavLinks[] = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "Products",
    path: "/products",
  },
  {
    name: "Blog",
    path: "/blogs",
  },
  {
    name: "Resource",
    path: "/resource",
  },
  {
    name: "Request a Quote",
    path: "/request-a-quote",
  },
];

const Footer = () => {
  const navigate = useNavigate();

  return (
    <div className="px-4 h-full text-white mx-auto py-10 sm:max-w-xl bg-[#1D0500] md:max-w-full lg:max-w-full md:px-24 lg:px-20">
      <div className="max-w-screen-2xl mx-auto">
        <svg
          className="mb-10"
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="2"
          viewBox="0 0 1247 2"
          fill="none"
        >
          <path d="M0.5 1H1246.5" stroke="url(#paint0_linear_5_150)" />
          <defs>
            <linearGradient
              id="paint0_linear_5_150"
              x1="0.5"
              y1="1.5"
              x2="1246.5"
              y2="1.5"
              gradientUnits="userSpaceOnUse"
            >
              <stop />
              <stop offset="0.5" stop-color="white" />
              <stop offset="1" />
            </linearGradient>
          </defs>
        </svg>
        <div className="grid gap-4 sm:gap-10 row-gap-6 sm:grid-cols-2 lg:grid-cols-4">
          <div className="flex flex-col w-full sm:col-span-2">
            <img src={logo} alt="" className="w-80" />
            <div className="mt-6 flex flex-wrap gap-4 lg:max-w-md">
              {leftNavLinks.map((item, index) => (
                <p key={item.name} className="flex cursor-pointer">
                  <div
                    onClick={() => {
                      navigate(item.path);
                      window.scrollTo(0, 0);
                    }}
                    className="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800"
                  >
                    {item.name}
                  </div>
                  {index !== leftNavLinks.length - 1 && (
                    <span className="text-white opacity-30 ml-2"> / </span>
                  )}
                </p>
              ))}
            </div>
            <div className="mt-14 mb-20 flex rotate-180 justify-end gap-x-6">
              <p
                className="cursor-pointer"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                <div className="text-center animate-bounce justify-center">
                  <div
                    className={`p-2 w-12 h-12 ring-1 ring-slate-200/20 shadow-lg rounded-full flex items-center justify-center`}
                  >
                    <>
                      <svg
                        className="w-6 h-6 text-white"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
                      </svg>
                    </>
                  </div>
                </div>
              </p>
            </div>
          </div>
          <div className="space-y-4 text-sm">
            <div className="space-y-2">
              <p className="text-sm tracking-wide text-primary">Contact us</p>
              <div className="flex">
                <span className="text-primary mr-1 text-2xl">(</span>
                <a
                  href="tel:+1-330-265-1776"
                  aria-label="Our phone"
                  title="Our phone"
                  className="transition-colors text-2xl duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800"
                >
                  +1 (330)-265-1776
                </a>
                <span className="text-primary ml-1 text-2xl">)</span>
              </div>
            </div>

            <div className="space-y-2">
              <p className="text-sm tracking-wide text-primary">Location</p>
              <div className="flex text-[#ADB3AB] w-[200px]">
                <p>2014 Champions Gateway #301 Canton, OH 44708, USA</p>
              </div>
            </div>

            <div className="space-y-2">
              <p className="text-sm tracking-wide text-primary">Timings</p>
              <div className="flex">
                <p className="transition-colors text-2xl duration-300 text-[#ADB3AB] hover:text-deep-purple-800">
                  open 24H a day 365 a year.
                </p>
              </div>
            </div>
            <div className="!mt-10">
              <p className="transition-colors text-[12px] duration-300 text-primary hover:text-deep-purple-800">
                © 2024 — Copyright
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-10 text-sm">
            <div className="flex flex-col text-sm">
              <PrimaryButton
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate("/login");
                }}
              >
                Admin login
              </PrimaryButton>
            </div>
            <div className="space-y-2">
              <p className="text-sm tracking-wide text-primary">Email</p>
              <div className="flex">
                <a
                  href="mailto:andy@TanksandCovers.com"
                  aria-label="Our phone"
                  title="Our phone"
                  className="transition-colors text-[#ADB3AB] duration-300"
                >
                  andy@TanksandCovers.com
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full justify-center mt-4 items-center">
          <span>Powered by</span>
          <a
            href="https://techqilla.com"
            rel="noopener noreferrer"
            target="_blank"
            aria-label="Our phone"
            title="Our phone"
            className="transition-colors ml-1 text-[#ADB3AB] duration-300"
          >
            Techqilla.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
