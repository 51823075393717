import React, { useContext, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikField from "../common/Field";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../service/service";
import { AuthContext } from "../../context/AuthContext";
import { toast } from "react-toastify";
import PrimaryButton from "../common/PrimaryButton";

interface LoginValues {
  email: string;
  password: string;
}

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});

const Login = () => {
  const authContext = useContext(AuthContext);
  // useEffect(() => {
  //   if (authContext !== undefined && authContext?.isAuthenticated) {
  //     navigate("/");
  //   }
  // }, [authContext]);

  // const navigate = useNavigate();
  const initialValues: LoginValues = {
    email: "",
    password: "",
  };

  const handleSubmit = async (values: LoginValues) => {
    try {
      const response = await loginUser(values);

      if (response.loggedIn) {
        authContext?.setIsAuthenticated(true);
        authContext?.setUser(response.user);
        toast.success("Login successful");
        return (window.location.href = "/create-blog");
      }
      console.log("Login successful");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="flex w-full mt-10 sm:mt-0 sm:flex-row justify-center h-screen">
      <div className="sm:w-[50%] my-auto px-5 sm:px-20">
        <h3 className="mb-20 text-6xl volkhov-regular text-[#484848]">
          Tanks and Covers: Admin login
        </h3>

        <h3 className="mb-6 text-3xl volkhov-regular">Login</h3>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <FormikField
              name="email"
              type="email"
              placeholder="Email"
              autoComplete="email"
            />
            <FormikField
              name="password"
              type="password"
              placeholder="Password"
              autoComplete="current-password"
            />
            <PrimaryButton type="submit">Sign in</PrimaryButton>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default Login;
