import React from "react";

import AOS from "aos";
import "aos/dist/aos.css";
import { Routes, Route } from "react-router-dom";
import HomePage from "./components/HomePage/HomePage";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import ResourcePage from "./components/ResourcesPage/ResourcePage";
import ProductPage from "./components/ProductPage/ProductPage";
import RequestPage from "./components/RequestPage/RequestPage";
import ContactPage from "./components/ContactPage/ContactPage";
import BlogDetailPage from "./components/Blogs/BlogDetailPage";
import { AuthProvider } from "./context/AuthContext";
import { BlogPage } from "./components/Blogs/BlogPage";
import CreateBlog from "./components/CreateBlog/CreateBlog";
import Login from "./components/Login/Login";
import { ToastContainer } from "react-toastify";

function App() {
  React.useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="">
      <AuthProvider>
        <Nav />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/resource" element={<ResourcePage />} />
          <Route path="/products" element={<ProductPage />} />
          <Route path="/request-a-quote" element={<RequestPage />} />
          <Route path="/blogs" element={<BlogPage />} />
          <Route path="/blog/:id" element={<BlogDetailPage />} />
          <Route path="/create-blog" element={<CreateBlog />} />
          <Route path="/contact-us" element={<ContactPage />} />
          <Route path="/login" element={<Login />} />
        </Routes>
        <Footer />
        <ToastContainer />
      </AuthProvider>
    </div>
  );
}

export default App;
