import React, { useState } from "react";
import { motion } from "framer-motion";
import questionBg from "../../assets/images/questionsBg.webp";
import questionBgSmall from "../../assets/images/questionBgSmall.webp";
import questionImg from "../../assets/images/questionsBgImg.webp";
import { useNavigate } from "react-router-dom";

type Question = {
  question: string;
  text: string;
  id: number;
};

const question: Question[] = [
  {
    id: 1,
    question: "1. What materials are bolted storage tanks typically made from?",
    text: "Bolted storage tanks are usually constructed from carbon steel or stainless steel, depending on the application and the contents being stored. Some may also use fiberglass or other composites for specific needs.",
  },
  {
    id: 2,
    question: "2. What are the advantages of using bolted storage tanks?",
    text: "Bolted tanks offer several advantages, including easier transport and assembly, flexibility in design, and the ability to expand or relocate the tank as needed. They are also generally more cost-effective compared to welded tanks.",
  },
  {
    id: 3,
    question:
      "3. How do bolted storage tanks handle corrosion and maintenance?",
    text: "Bolted tanks are often designed with corrosion-resistant coatings and materials. Regular inspections and maintenance are necessary to ensure longevity, and any damaged or corroded sections can typically be replaced without needing to replace the entire tank.",
  },
  {
    id: 4,
    question:
      "4. What is the typical installation process for bolted storage tanks?",
    text: "The installation process generally involves site preparation, assembling the tank components, securing bolts, and ensuring proper sealing and structural integrity. It is crucial to follow manufacturer guidelines for installation to ensure safety and functionality.",
  },
  {
    id: 5,
    question: "5. What types of content can be stored in bolted storage tanks?",
    text: "Bolted storage tanks can store a wide range of liquids, including water, chemicals, petroleum products, and wastewater, among others. However, the suitability for specific contents should always be verified based on the tank's material and design specifications.",
  },
];

const Questions = () => {
  const navigate = useNavigate();
  const [activeId, setActiveId] = useState<number | null>(null);

  const toggleFAQ = (id: number) => {
    setActiveId(activeId === id ? null : id); // Toggle active state
  };

  return (
    <div className="flex flex-col sm:flex-row my-6 sm:my-10 relative w-full h-full mx-auto max-w-screen-2xl items-center justify-center">
      <div
        className="max-w-[803px] relative w-full sm:ml-10 z-10 h-[600px] bg-right flex flex-col justify-between pt-10 sm:pt-20 pl-8 sm:pl-16 text-white"
        style={{ backgroundImage: `url(${questionBg})` }}
      >
        <div className="flex flex-col">
          <h1 className="text-xl sm:text-3xl kalnia-regular">
            Have any questions?
          </h1>
          <h1 className="text-xl sm:text-3xl !font-bold kalnia-regular">
            our expert can help
          </h1>
          <p className="mt-6 max-w-[300px] sm:max-w-[500px]">
            Tarsco Bolted Tank is a leading manufacturer of epoxy-coated and
            stainless-steel bolted tanks, serving various industries like water,
            wastewater, and dry bulk storage.
          </p>

          <motion.button
            onClick={() => {
              window.scrollTo(0, 0);
              navigate("/contact-us");
            }}
            className="py-2 px-5 mt-6 bg-[#ff4a23] max-w-[140px] border border-white rounded-full text-white hover:bg-white hover:text-black transition-all duration-300"
          >
            Contact us
          </motion.button>
        </div>

        <div className="sm:hidden flex justify-end mr-6">
          <img
            src={questionImg}
            alt=""
            className="max-h-[200px] w-[200px] object-cover"
          />
        </div>
      </div>
      <div className="hidden sm:block absolute bottom-0 left-0 z-10">
        <img
          src={questionImg}
          alt=""
          className="max-h-[200px] w-[200px] object-cover"
        />
      </div>
      <div className="sm:h-[600px] flex flex-col justify-between w-full z-0 pt-20">
        <div className="flex flex-col w-full">
          <h1 className="text-xl px-4 sm:text-3xl kalnia-regular">
            Frequently Asked Questions
          </h1>
          <div className="mt-6 px-4 max-w-[500px] max-h-[400px] overflow-scroll">
            {question.map((item) => (
              <div
                key={item.id}
                className="border rounded-lg mb-4 border-black overflow-hidden shadow-sm"
              >
                {/* Question */}
                <button
                  onClick={() => toggleFAQ(item.id)}
                  className={`w-full text-left p-2 kalnia-regular text-lg flex justify-between items-center transition-all duration-300 ${
                    activeId === item.id
                      ? "bg-primary text-white"
                      : "bg-transparent text-secondary"
                  }`}
                >
                  {item.question}
                  <span className="text-xl poppins-regular">
                    {activeId === item.id ? "−" : "+"}
                  </span>
                </button>

                {/* Answer */}
                {activeId === item.id && (
                  <div className="p-4  text-secondary montserrat-regular ">
                    <p>{item.text}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="hidden sm:flex justify-end">
          <img
            src={questionBgSmall}
            alt=""
            className="max-h-[200px] w-[200px] object-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default Questions;
