import React from "react";
import { Link } from "react-router-dom";
import { BlogType } from "../../types/types";
import { format } from "date-fns";
// import { deleteBlog } from "../../service/service";
import { toast } from "react-toastify";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";

import img from "../../assets/images/ourWork5.webp";
import right from "../../assets/images/right.webp";
import { deleteBlog } from "../../service/service";

interface BlogProps {
  blog: BlogType;
  isEditable?: boolean;
  setMode?: React.Dispatch<React.SetStateAction<"create" | "edit">>;
  setEditBlogData?: React.Dispatch<React.SetStateAction<BlogType>>;
  setBlogs?: React.Dispatch<React.SetStateAction<BlogType[]>>;
}

const delBlog = async (
  blogId: string,
  setJobs: React.Dispatch<React.SetStateAction<BlogType[]>>,
) => {
  if (!window.confirm("Are you sure you want to delete this blog?")) {
    return;
  }

  try {
    const response = await deleteBlog(blogId);
    if (response.status === false) {
      console.log("Job deletion failed:", response.response?.data.message);
    } else {
      setJobs((prevJobs) => prevJobs.filter((job) => job._id !== blogId));
      console.log("Job deleted successfully:");
      toast.success("Job deleted successfully");
    }
  } catch (error) {
    toast.error("Error deleting job");
    console.error("Error deleting job:", error);
  }
};

const FirstBlog = ({
  blog,
  isEditable,
  setMode,
  setEditBlogData,
  setBlogs,
}: BlogProps) => {
  return (
    <div className="relative">
      {isEditable && setMode && setEditBlogData && setBlogs && (
        <div className="absolute z-10 flex gap-2 top-6 right-6">
          <div
            onClick={() => delBlog(blog._id, setBlogs)}
            className="p-2 bg-gray-300 rounded-full"
          >
            <RiDeleteBin6Line className="opacity-50 cursor-pointer " />
          </div>
          <div
            onClick={() => {
              setMode("edit");
              setEditBlogData(blog);
              window.scrollTo(0, 0);
            }}
            className="p-2 bg-gray-300 rounded-full"
          >
            <FaEdit className="opacity-50 cursor-pointer " />
          </div>
        </div>
      )}
      <Link
        to={`/blog/${blog?._id}`}
        state={{ blog }}
        onClick={() => window.scrollTo(0, 0)}
        className="flex"
      >
        <div className="flex flex-col p-4 border h-full sm:flex-row mx-auto max-w-screen-xl justify-center">
          <div className="sm:w-[50%] h-[300px] sm:h-[400px] overflow-hidden">
            <img src={blog?.image as unknown as string} alt="feature" className="w-full h-full object-cover" />
          </div>
          <div className="sm:w-[50%] sm:max-h-[400px] py-6 flex gap-6 justify-between flex-col px-4">
            <div className="flex flex-col gap-4 sm:max-h-[200px]">
              <h1 className="text-xl lg:text-2xl kalnia-regular font-semibold">
                {blog?.title}
              </h1>
              <p className="poppins-regular text-sm sm:text-base sm:max-w-[600px]">
                {blog?.description.split(" ").slice(0, 50).join(" ")}...
              </p>
            </div>

            <div className="flex w-full items-center justify-between gap-4">
              <p className="poppins-regular text-gray-500">
                {format(new Date(), "MMMM dd, yyyy")} | {blog?.author}
              </p>

              <Link
                to={`/blog/${blog?._id}`}
                state={{ blog }}
                onClick={() => window.scrollTo(0, 0)}
                className="px-3 py-4 hover:px-8 transition-all duration-300 prev-btn flex justify-center items-center rounded-full border border-black"
              >
                <img src={right} className="w-4" alt="Previous" />
              </Link>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default FirstBlog;
