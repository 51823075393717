import React from "react";
import img from "../../assets/images/requestheroimg.webp";
import { motion } from "framer-motion";

const RequestHeroSection = () => {
  return (
    <div className="max-w-screen-2xl mx-auto pb-6 sm:py-16 overflow-hidden">
      <div className="flex justify-between flex-col-reverse sm:flex-row">
        <div className="sm:w-[45%] z-10 relative overflow-hidden">
          <div className="pb-6 sm:pb-12 h-full">
            <motion.img
              initial={{ x: -200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 1 }}
              src={img}
              alt=""
              className="w-full object-cover h-full"
            />
            <div className="absolute bottom-0 w-[150px] h-[220px] bg-[#ff4a23] skew-x-12 -left-20"></div>
          </div>
        </div>
        <div className="sm:w-[65%] h-full">
          <div className="items-center sm:border-b">
            <div className="relative w-full h-[110px] bg-[#ff4a23]">
              <div className="absolute flex items-center bottom-0 left-24 h-full sm:w-[90%] bg-white transform -ml-20 sm:-ml-4 skew-x-12 origin-left px-4">
                <motion.h1
                  initial={{ x: -200, opacity: 0, skewX: -12 }}
                  whileInView={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  className="text-2xl lg:text-5xl kalnia-regular font-semibold -skew-x-12"
                >
                  Turn your vision into reality
                </motion.h1>
              </div>
            </div>
          </div>
          <div className="w-full sm:pr-48">
            <motion.p
              initial={{ x: 200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.5 }}
              className="px-4 sm:px-8 py-4 sm:ml-16 sm:mt-20 poppins-regular text-base"
            >
              We’re thrilled to connect with you! We encourage you to take a
              moment to complete the information below and share your specific
              needs and interests with us. Whether you have questions, requests,
              or just want to learn more about what we offer, your input is
              invaluable to us.
              <br /> <br />
              If you happen to be in Canton, OH, we warmly invite you to visit
              and enjoy a complimentary tour of the Pro Football Hall of Fame!
              It’s an incredible experience where you can immerse yourself in
              the rich history and legacy of the sport. We think you’ll find it
              both inspiring and enjoyable.
              <br /> <br />
              For any inquiries or additional information, please fill out the
              form below, and rest assured that we’ll respond within 24 hours.
              We’re here to assist you and look forward to the opportunity to
              connect! Thank you for considering us, and we’re excited to hear
              from you soon.
            </motion.p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestHeroSection;
