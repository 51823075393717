import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { BlogType } from "../../types/types";
import BlogList from "./BlogList";
import Pagination from "./Pagination";

type Props = {
  isEditable?: boolean;
  setMode?: React.Dispatch<React.SetStateAction<"create" | "edit">>;
  setEditBlogData?: React.Dispatch<React.SetStateAction<BlogType>>;
};

const Blogs = ({ isEditable, setMode, setEditBlogData }: Props = {}) => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    return null;
  }

  return (
    <>
      <div className="flex flex-col overflow-hidden mx-auto max-w-screen-2xl items-center justify-center">
        <div className="py-6">
          <BlogList
            isEditable={isEditable}
            setMode={setMode}
            setEditBlogData={setEditBlogData}
            setBlogs={authContext.setBlogs}
            blogs={authContext.blogs}
          />
          <Pagination
            currentPage={authContext.currentPage}
            totalPages={authContext.totalPages}
            onPageChange={authContext.handlePageChange}
          />
        </div>
      </div>
    </>
  );
};

export default Blogs;
