import React from "react";
import img from "../../assets/images/resourceimg3.svg";
const Contractor = () => {
  return (
    <div className="max-w-screen-2xl mx-auto pb-6 sm:pb-24">
      <div className="flex justify-between items-start flex-col-reverse sm:flex-row">
        <div className="sm:w-[60%]">
          <div className="items-center border-b">
            <div className="hidden sm:flex relative w-full h-[110px] bg-[#ff4a23]">
              <div className="absolute bottom-0 left-24 h-full w-[90%] bg-white transform skew-x-12 origin-left px-4">
                <h1 className="text-4xl lg:text-5xl kalnia-regular font-semibold flex items-center h-full -skew-x-12">
                  Contractors
                </h1>
              </div>
            </div>
            <div className="sm:hidden flex mt-6 mb-4 mx-4">
              <h1 className="text-4xl lg:text-5xl kalnia-regular font-semibold flex items-center">
                Contractors
              </h1>
            </div>
          </div>
          <p className="p-4 sm:px-8 sm:ml-16 sm:mt-8 poppins-regular text-base">
            Attention Bolted Tank Contractors: Partnership Opportunities with Us:
            <br/><br/>
            If you’re a contractor actively installing bolted tanks, we’d love to connect
            with you. As industry leaders in storage solutions, we’re always
            looking to build partnerships with professionals like you to expand
            our network and enhance the delivery of high-quality bolted tank
            projects. <br/> <br/>By working together, we can create mutually beneficial
            opportunities, streamline project execution, and ensure your clients
            receive the best in tank systems and support. Let’s combine our
            expertise to take your installations to the next level. <br/><br/>Reach out to
            us directly to explore how we can collaborate and grow together!
          </p>
        </div>
        <img src={img} alt="" className="sm:w-[50%] h-[550px] z-10 object-cover" />
      </div>
    </div>
  );
};

export default Contractor;
