import React from "react";
import ContactForm from "./ContactForm";

const Connect = () => {
  return (
    <div className="flex flex-col bg-[#1D0500] overflow-hidden mx-auto items-center justify-center">
      <div className="max-w-screen-2xl w-full flex flex-col sm:flex-row sm:mt-[72px]">
        <ContactForm />
        <div className="px-4 py-4 sm:px-6 lg:px-8 sm:py-12">
          <iframe
            title="map"
            className="sm:w-[600px] sm:h-[600px] w-full max-w-[600px]"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12077.352717403543!2d-81.399424!3d40.820535!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8836d0ec922da3e3%3A0x842214d221907028!2s2014%20Champions%20Gateway%2C%20Canton%2C%20OH%2044708%2C%20USA!5e0!3m2!1sen!2sin!4v1733813205857!5m2!1sen!2sin"
            style={{ border: 0 }}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
      </div>
    </div>
  );
};

export default Connect;
