import React from "react";
import { Field } from "formik";
import FormikErrorMessage from "./ErrorField";

interface FormikFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  className?: string;
  label?: string;
  placeholder?: string;
  as?: string;
  rows?: number;
}

const FormikField = ({
  name,
  label,
  className,
  placeholder,
  as,
  rows,
  ...props
}: FormikFieldProps) => {
  return (
    <div className="mb-4 w-full">
      {label && (
        <label
          htmlFor={name}
          className="block mb-1 text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      )}
      <Field
        as={as}
        rows={rows}
        id={name}
        name={name}
        placeholder={placeholder}
        className={`w-full px-4 text-white  border-b border-l border-r rounded-2xl focus:outline-none border-[#ff4a23] py-2 bg-transparent`}
        {...props}
      />
      <FormikErrorMessage name={name} />
    </div>
  );
};

export default FormikField;
