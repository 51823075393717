import React from "react";
import heroimg from "../../assets/images/resourceheroimg.svg";
import { motion } from "framer-motion";

const HeroSection = () => {
  return (
    <div className="max-w-screen-2xl mx-auto sm:py-24 overflow-hidden">
      <div className="flex justify-between flex-col sm:flex-row">
        <div className="sm:w-[65%]">
          <div className="items-center sm:border-b">
            <div className="relative w-full h-[110px] bg-[#ff4a23]">
              <div className="absolute flex items-center bottom-0 left-24 h-full sm:w-[90%] bg-white transform -ml-20 sm:-ml-4 skew-x-12 origin-left px-4">
                <motion.h1
                  initial={{ x: -200, opacity: 0, skewX: -12 }}
                  whileInView={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  className="text-2xl lg:text-5xl kalnia-regular font-semibold -skew-x-12"
                >
                  Resources are at your fingertips
                </motion.h1>
              </div>
            </div>
          </div>
          <motion.p
            initial={{ x: -200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            className="px-4 sm:px-8 py-4 sm:ml-16 sm:mt-20 poppins-regular text-base"
          >
            TanksandCovers.com is committed to providing customized
            specifications, detailed drawings, and comprehensive product data
            sheets tailored to meet the unique needs of your specific upon
            request. This page is dedicated to supplying essential engineering
            details that enhance and refine project specifications, ensuring
            that every aspect is meticulously addressed.
            <br /> <br />
            Our primary goal is to empower engineers with vital resources that
            streamline their workflow and boost efficiency, allowing them to
            focus on what they do best. With over 30 years of industry
            experience, we pride ourselves on delivering valuable information in
            a way that is more effective and accessible than anyone else in the
            market. Trust us to be your go-to source for all your engineering
            needs.
          </motion.p>
        </div>
        <motion.img
          initial={{ x: 200, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          src={heroimg}
          alt=""
          className="sm:w-[45%] z-10"
        />
      </div>
    </div>
  );
};

export default HeroSection;
