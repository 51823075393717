import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { type Swiper as SwiperTypes } from "swiper";
import { Autoplay, Pagination, Navigation, Thumbs } from "swiper/modules";
import monitor from "../../assets/images/monitoricon.svg";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import img1 from "../../assets/images/resourceSliderImg1.webp";
import img2 from "../../assets/images/resourceSliderImg2.webp";
import img3 from "../../assets/images/resourceSliderImg3.webp";
import img4 from "../../assets/images/resourceSliderImg4.webp";
import img5 from "../../assets/images/resourceSliderImg5.webp";

import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";

const Monitoring = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperTypes | null>(null);
  const imgs = [img1, img2, img3, img4, img5];

  return (
    <div className="max-w-screen-2xl pb-6 sm:pb-24 sm:mx-0">
      <div className="flex w-full mt-4 overflow-hidden mb-4 sm:mb-20">
        {/* Left orange bar */}
        <div className="h-[110px] w-[12%] bg-[#ff4a23] transform skew-x-12 -ml-6"></div>

        {/* Text and icon section */}
        <div className="flex items-center border-b bg-white px-6 sm:w-[60%]">
          <span className="text-green-500 sm:text-3xl mr-2">
            {" "}
            <img src={monitor} />
          </span>{" "}
          {/* Replace with your actual SVG icon */}
          <h1 className="text-xl lg:text-5xl kalnia-regular font-semibold">
            Smart Tank Monitoring
          </h1>
        </div>

        {/* Right orange bar */}
        <div className="h-[110px] w-[50%] bg-[#ff4a23] transform -skew-x-12 -mr-10"></div>
      </div>
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="sm:w-[50%] sm:px-8 sm:ml-14 mx-4 text-sm sm:text-base">
          <p>
            Green Arrow's Smart Tank monitoring system is an advanced
            technological solution designed to oversee and manage the status of
            storage tanks from the moment materials are introduced to the system
            by tipping solids into a hopper or dropping liquids through a hose.
            It utilizes sensors and IoT connectivity to provide real-time data
            on various parameters, such as liquid levels, temperature, pressure,
            and even chemical composition. The system monitors the motors on
            conveyors, pumps, valves, and more, offering clients enhanced
            visibility into their complete operations. It enables them to
            optimize inventory management, reduce the risk of spills or
            overflows, and ensure compliance with safety regulations.
            <br /> <br /> Smart Tank Monitoring System is designed for fingertip
            operation, allowing users to easily access critical information and
            control functionalities through a mobile app or web interface. It
            can provide alerts and notifications for maintenance needs or
            irregularities, ultimately leading to improved efficiency and cost
            savings in tank management. The convenience of fingertip operation
            ensures that operators can swiftly respond to any issues, enhancing
            overall safety and operational reliability.
            <br />
            <br /> Through its innovative tank design and management strategies,
            Smart Tank Technologies is a strong ally for various industries. Its
            cutting-edge technology improves efficiency and safety, allowing
            tanks to be monitored in real-time to ensure optimal performance.
            This function helps reduce environmental risks and lower maintenance
            expenses.
          </p>
        </div>
        <div className="sm:w-[50%] mx-4">
          <Swiper
            modules={[Navigation, Pagination, Autoplay, Thumbs]}
            navigation={{
              nextEl: ".next-btn",
              prevEl: ".prev-btn",
            }}
            autoplay={true}
            thumbs={{
              swiper:
                thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
            }}
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
          >
            {imgs.map((img, index) => (
              <SwiperSlide key={index}>
                <img src={img} alt="" className=" w-full object-cover" />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="flex py-4 sm:p-4 gap-4 justify-center items-center sm:mx-4">
            <div className="hidden sm:block">
              <button className="px-4 py-7 flex prev-btn justify-center bg-white hover:bg-primary hover:border-none border border-black group btn-disabled disabled:bg-white disabled:border w-16 h-16 items-center rounded-full disabled:border-black">
                <FaArrowLeftLong className="sm:text-3xl text-black group-hover:text-white" />
              </button>
            </div>
            <Swiper
              loop={true}
              onSwiper={setThumbsSwiper}
              spaceBetween={20}
              slidesPerView={5}
              freeMode={true}
              breakpoints={{
                320: {
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 4,
                },
                1024: {
                  slidesPerView: 5,
                },
              }}
              watchSlidesProgress={true}
              modules={[Navigation, Thumbs]}
              className="mySwiper"
            >
              {imgs.map((img, index) => (
                <SwiperSlide key={index}>
                  <img
                    src={img}
                    alt=""
                    className="w-20 h-10 sm:h-20 object-cover cursor-pointer"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="hidden sm:block">
              <button className="px-4 py-7 group flex next-btn justify-center border hover:border-none border-black bg-white hover:bg-primary btn-disabled disabled:bg-white disabled:border w-16 h-16 items-center rounded-full disabled:border-black">
                <FaArrowRightLong className="sm:text-3xl text-black group-hover:text-white" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Monitoring;
