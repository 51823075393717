import React from "react";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  children: React.ReactNode;
}

const PrimaryButton = ({ className, children, ...props }: ButtonProps) => {
  return (
    <button
      className={`bg-primary max-w-[220px] hover:-translate-y-1 disabled:cursor-not-allowed disabled:opacity-50 transition-all duration-300 text-white py-2 px-4 rounded-lg ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default PrimaryButton;
