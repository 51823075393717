import React, { useState } from "react";
import expSecImg from "../../../assets/images/expSectionImg.webp";
import { FaPlus, FaMinus } from "react-icons/fa6";
import { motion } from "framer-motion";

interface TabContent {
  title: string;
  items: {
    heading: string;
    subItems?: string[];
  }[];
}

interface TabsData {
  level: number;
  content: TabContent;
}

const Expandable = () => {
  const [expandedTab, setExpandedTab] = useState<number>(1);

  const tabsData: TabsData[] = [
    {
      level: 1,
      content: {
        title: "Engineering",
        items: [
          {
            heading: "1. Project Manager Assignment",
            subItems: ["Customer submittal process"],
          },
          {
            heading: "2. Preliminary designs for project budgeting",
            subItems: ["Foundation design", "Tank Design"],
          },
          {
            heading: "3. PE stamped drawings",
            subItems: ["Including standard and special design capabilities."],
          },
          {
            heading: "4. Tank design",
            subItems: ["Standard tank design and special design capabilities."],
          },
          {
            heading: "5. Ancillary equipment integration",
            subItems: [
              "Spiral, vertical, and special staircases",
              "Access",
              "Mixers, pumps, heating, explosion-proof systems, etc.",
            ],
          },
          { heading: "6. Construction drawings" },
        ],
      },
    },
    {
      level: 2,
      content: {
        title: "Manufacturing",
        items: [
          { heading: "1. Project Manager Assignment" },
          {
            heading: "2. Carbon epoxy-coated tanks",
            subItems: [
              "Precision manufacturing (laser technologies).",
              "Cutting, forming, and welding",
              "Industry-leading powder for powder coating on overhead monorail production line.",
              "State-of-the-art packaging line for less handling and more control.",
              "QCQA - inspection process with zero tolerance.",
            ],
          },
          {
            heading: "3. Stainless steel tanks",
            subItems: [
              "Precision manufacturing (laser technologies).",
              "Cutting, forming, and welding",
              "State-of-the-art packaging line for less handling and more control.",
              "QCQA - inspection process with zero tolerance.",
            ],
          },
          {
            heading: "4. Ancillary and access equipment",
            subItems: [
              "Precision manufacturing (laser technologies).",
              "Cutting, forming, and welding",
              "State-of-the-art packaging line for less handling and more control.",
              "QCQA - inspection process with zero tolerance.",
            ],
          },
        ],
      },
    },
    {
      level: 3,
      content: {
        title: "Construction",
        items: [
          {
            heading: "1. Project Manager Assignment(PMA)",
          },
          {
            heading: "2. Preconstruction meeting with customer",
          },
          {
            heading: "3. Dedicated foreman for single source on-site contact.",
          },
          {
            heading: "4. Daily reports are provided.",
            subItems: ["Foreman to client job site walks"],
          },
          {
            heading: "5. Weekly on-site update meetings",
          },
          {
            heading:
              "6. completion form signed off by the client. We do not leave until the client is 100% signed off and satisfied.",
          },
        ],
      },
    },
  ];

  const getLeftVal = (idx: number) => {
    switch (idx) {
      case 0:
        return "left-0";
      case 1:
        return "left-[320px] sm:left-[440px]";
      case 2:
        return "left-[365px] sm:left-[540px]";

      default:
        break;
    }
  };

  const getRightVal = (idx: number) => {
    switch (idx) {
      case 0:
        return "";
      case 1:
        return "-translate-x-60 sm:-translate-x-80";
      case 2:
        return "-translate-x-60 sm:-translate-x-80";
    }
  };

  const toggleTab = (level: number) => {
    setExpandedTab(expandedTab === level ? level - 1 : level);
  };

  return (
    <div className="z-20 flex flex-col sm:flex-row items-center justify-center h-full">
      <div className="flex w-full max-w-4xl items-start gap-2 bottom-0 min-h-[900px] px-4">
        {tabsData.map((tab, index) => {
          const isExpanded = expandedTab === tab.level;
          // const isAfterExpanded = expandedTab !== null && tab.level > expandedTab;

          return (
            <div
              key={index}
              className={`transition-all w-full ${getLeftVal(index)} absolute shadow-md duration-500 ease-in-out ${index === 1 && expandedTab === 3 ? "-translate-x-60 sm:-translate-x-80" : ""} ${index !== 0 && isExpanded ? getRightVal(index) : ""}`}
              style={{
                zIndex: index,
              }}
            >
              <div
                className={`
               text-white p-2 sm:p-6 h-[850px]
${index === 0 ? "bg-[#FF5733]" : "bg-[#FF4A23]"}
                transition-all duration-300 ease-in-out
              `}
              >
                <div className="flex flex-row items-start mb-4 poppins-regular">
                  <div className="flex items-center flex-col gap-2">
                    <div className="text-4xl sm:text-6xl font-bold mb-2">
                      {tab.level}
                    </div>
                    <div
                      className={`text-xl sm:text-2xl ${isExpanded ? "rotate-0 -mt-4" : "rotate-90"} transition-all duration-500 ease-in-out`}
                    >
                      Level
                    </div>
                  </div>
                </div>

                {tab.content && (
                  <div className="flex flex-row items-start h-full mb-4">
                    {index !== 0 && (
                      <div className="flex relative justify-center items-center h-full">
                        <button
                          onClick={() => toggleTab(tab.level)}
                          className="absolute px-10 py-10 shadow-md -z-20 bg-[#FF4A23] rounded-full transition-colors mb-28"
                        >
                          <div className="w-8 h-8"></div>
                        </button>

                        <div>
                          {isExpanded ? (
                            <div className="absolute">
                              <FaMinus
                                onClick={() => toggleTab(tab.level)}
                                className="absolute cursor-pointer w-6 h-6 -top-16 -left-8"
                              />
                            </div>
                          ) : (
                            <div className="relative">
                              <FaPlus
                                onClick={() => toggleTab(tab.level)}
                                className="absolute cursor-pointer w-6 h-6 -top-16 -left-8"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    <div
                      className={`
                  transition-all duration-300
                  ${isExpanded ? "opacity-100 sm:w-[400px]" : "opacity-0 w-[400px] "}
                `}
                    >
                      <h2 className="text-xl sm:text-2xl font-serif mb-4 kalnia-regular">
                        {tab.content.title}
                      </h2>
                      <div className="space-y-4 max-w-[280px] poppins-regular">
                        {tab.content.items.map((item, itemIndex) => (
                          <div key={itemIndex}>
                            <div className="font-medium">{item.heading}</div>
                            {item.subItems && (
                              <ul className="ml-4">
                                {item.subItems.map((subItem, subIndex) => (
                                  <li
                                    key={subIndex}
                                    className="list-disc ml-4 text-sm"
                                  >
                                    {subItem}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <motion.img
        initial={{ x: 200 }}
        whileInView={{ x: 0 }}
        transition={{ duration: 1 }}
        src={expSecImg}
        alt=""
        className="sm:absolute sm:max-w-[50%] h-full object-cover z-50 sm:right-0 sm:top-0"
      />
    </div>
  );
};

export default Expandable;
