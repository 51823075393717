import React, { createContext, useState, useEffect, ReactNode } from "react";
import { toast } from "react-toastify";
import { fetchBlogs, logoutUser, meCall } from "../service/service";
import { BlogType } from "../types/types";
import { blogs as resourceBlogs } from "../resource/blogs";

export type UserOrNull = {
  id: string;
  email: string;
} | null;

export interface AuthContextType {
  isAuthenticated: boolean;
  blogs: BlogType[];
  setBlogs: React.Dispatch<React.SetStateAction<BlogType[]>>;
  currentPage: number;
  totalPages: number;
  handlePageChange: (page: number) => void;
  user: UserOrNull;
  setUser: React.Dispatch<React.SetStateAction<UserOrNull>>;
  loading: boolean;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  logout: () => void;
}

export const checkAuthStatus = async () => {
  try {
    const response = await meCall();
    if (response.status === 200) {
      return { user: response.user, status: true };
    } else {
      return { user: null, status: false };
    }
  } catch (error) {
    console.error("Error authentication status:", error);
    return { user: null, status: false };
  }
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<UserOrNull>(null);
  const [loading, setLoading] = useState(false);
  const [blogs, setBlogs] = useState<BlogType[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const limit = 9;

  useEffect(() => {
    const getBlogs = async () => {
      try {
        const data = await fetchBlogs(currentPage, limit);
        if (data.status === false) {
          toast.error(data.response?.data.message);
        } else {
          setBlogs(data.response?.data.blogs as BlogType[]);
          setTotalPages(data.response?.data.totalPages as number);
        }
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    getBlogs();
  }, [currentPage]);

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }

    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const checkStatus = async () => {
    setLoading(true);
    const status = await checkAuthStatus();
    if (status.status) {
      setIsAuthenticated(true);
      setUser(status.user);
    }
    setLoading(false);
  };

  const logout = async () => {
    setIsAuthenticated(false);
    setUser(null);
    await logoutUser();
    toast.success("Logged out successfully");
    window.location.reload();
  };

  useEffect(() => {
    (async () => {
      await checkStatus();
    })();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        handlePageChange,
        blogs,
        currentPage,
        totalPages,
        setBlogs,
        isAuthenticated,
        logout,
        loading,
        user,
        setUser,
        setIsAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
