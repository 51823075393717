import React from "react";
import Expandable from "./Expandable";
import { motion } from "framer-motion";

const OurProcess = () => {
  return (
    <div className="flex flex-col overflow-hidden mx-auto max-w-screen-2xl relative z-10">
      <motion.div
        initial={{ y: 200, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <motion.h1 className="text-xl sm:text-3xl kalnia-regular w-[230px] sm:w-[340px] mx-4 sm:ml-20 mt-6 sm:mt-10 font-semibold mb-6 sm:mb-20">
          How do we complete our work process
        </motion.h1>
      </motion.div>
      <Expandable />
    </div>
  );
};

export default OurProcess;
