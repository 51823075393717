import React from "react";
import { motion } from "framer-motion";
import { BlogType } from "../../types/types";
import Blog from "./Blog";
import FirstBlog from "./FirstBlog";

interface BlogListProps {
  blogs: BlogType[];
  isEditable?: boolean;
  setMode?: React.Dispatch<React.SetStateAction<"create" | "edit">>;
  setEditBlogData?: React.Dispatch<React.SetStateAction<BlogType>>;
  setBlogs?: React.Dispatch<React.SetStateAction<BlogType[]>>;
}

const BlogList = ({
  blogs,
  isEditable,
  setMode,
  setEditBlogData,
  setBlogs,
}: BlogListProps) => {
  if (blogs.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center gap-4 py-10">
        <motion.h1
          className="text-3xl font-semibold kalnia-regular"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          No Blogs Found
        </motion.h1>
      </div>
    );
  }

  const firstBlog = blogs[0];
  const featureBlogs = blogs.slice(0, 2);
  const restBlogs = blogs.slice(2);

  return (
    <div className="flex flex-col gap-10 overflow-hidden mx-auto max-w-screen-2xl items-center justify-center">
      <FirstBlog
        setBlogs={setBlogs}
        setMode={setMode}
        setEditBlogData={setEditBlogData}
        blog={firstBlog}
        isEditable={isEditable}
      />
      <div className="flex flex-col justify-center items-center w-full">
        <motion.h1 className="text-3xl px-4 sm:px-0 mb-4 text-start w-full max-w-screen-xl lg:text-4xl kalnia-regular font-semibold flex items-end h-full">
          Featured Blogs
        </motion.h1>

        <div className="flex flex-col sm:flex-row gap-4 max-w-screen-xl">
          {featureBlogs.map((blog) => (
            <Blog
              isEditable={isEditable}
              setMode={setMode}
              setEditBlogData={setEditBlogData}
              setBlogs={setBlogs}
              key={blog._id}
              blog={blog}
            />
          ))}
        </div>
      </div>

      <div className="grid grid-cols-1 w-full gap-4 mb-2 md:grid-cols-2 sm:mb-4 max-w-screen-xl">
        {restBlogs.map((blog) => (
          <Blog
            isEditable={isEditable}
            setMode={setMode}
            setEditBlogData={setEditBlogData}
            setBlogs={setBlogs}
            key={blog._id}
            blog={blog}
          />
        ))}
      </div>
    </div>
  );
};

export default BlogList;
