import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikField from "../common/FormikField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import date from "../../assets/images/date.webp";

type FormValues = {
  firstName: string;
  lastName: string;
  company: string;
  email: string;
  projectInfo: string;
  date?: Date;
  location?: string;
  tanks: boolean;
  covers: boolean;
};

const Contact = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [checkCover, setCheckCover] = useState(false);
  const [checkTanks, setCheckTanks] = useState(false);

  const initialValues: FormValues = {
    firstName: "",
    lastName: "",
    company: "",
    email: "",
    projectInfo: "",
    date: new Date(),
    location: "",
    tanks: false,
    covers: false,
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    company: Yup.string().required("Company is required"),
    location: Yup.string(),
    date: Yup.string(),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    projectInfo: Yup.string().required("Please enter project details"),
  });

  const handleSubmit = async (values: FormValues) => {
    values.tanks = checkTanks;
    values.covers = checkCover;
    console.log(values);
  };
  return (
    <div className="bg-[#1D0500]">
      <div className="max-w-screen-2xl mx-auto px-4 sm:px-6 lg:px-14 py-12">
        <h1 className="text-4xl font-bold mb-6 kalnia-regular text-white">
          Contact Details
        </h1>
        <div className="w-full flex flex-col sm:flex-row">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values }) => (
              <Form className="space-y-6 w-full">
                <div className="flex flex-col sm:flex-row gap-10 w-full justify-between">
                  <div className="w-full flex flex-col gap-4">
                    <div className="w-full">
                      <FormikField name="firstName" placeholder="First Name" />
                    </div>
                    <div className="w-full ">
                      <FormikField name="lastName" placeholder="Last Name" />
                    </div>

                    <div className="w-full ">
                      <FormikField name="email" placeholder="Email" />
                    </div>

                    <div className="w-full">
                      <FormikField name="company" placeholder="Company Name" />
                    </div>
                    <div className="w-full">
                      <FormikField
                        name="location"
                        id="location"
                        placeholder="Project Location"
                      />
                    </div>
                    <div className="w-full flex justify-around text-[#989EAA] opacity-75 ">
                      Type of projects :
                      <div
                        onClick={() => setCheckCover(!checkCover)}
                        className="flex gap-4 items-center cursor-pointer"
                      >
                        <div
                          className={`size-4 border-b border-l border-r rounded focus:outline-none border-[#ff4a23] ${checkCover ? "bg-[#ff4a23]" : ""
                            } `}
                        ></div>
                        Cover
                      </div>
                      <div
                        onClick={() => setCheckTanks(!checkTanks)}
                        className="flex gap-4 items-center cursor-pointer"
                      >
                        <div
                          className={`size-4 border-b border-l border-r rounded focus:outline-none border-[#ff4a23] ${checkTanks ? "bg-[#ff4a23]" : ""
                            } `}
                        ></div>
                        Tank
                      </div>
                    </div>
                  </div>
                  <div className="w-full space-y-6">
                    <div className="w-full relative">
                      <DatePicker
                        selected={values.date}
                        id="date"
                        className={`w-full px-4 text-[#989EAA]  border-b border-l border-r rounded-2xl focus:outline-none border-[#ff4a23] py-2 bg-transparent`}
                        name="date"
                        onChange={(date) => {
                          setFieldValue("date", date);
                        }}
                      />
                      <img
                        src={date}
                        alt=""
                        className="absolute top-2 right-4"
                      />
                    </div>
                    <div className="w-full">
                      <FormikField
                        name="projectInfo"
                        as="textarea"
                        rows={4}
                        placeholder="Tell us about your project!"
                      />
                    </div>
                    <div className="border relative border-[#ff4a23] rounded-3xl text-[#989EAA] border-dashed flex flex-col justify-center items-center py-11 ">
                      <input
                        className="opacity-0 w-full h-full absolute top-0"
                        type="file"
                      />
                      Drag file here or clickthe button below
                      <button className="py-2 px-8 border border-gray-500 rounded-xl mt-2">
                        Upload
                      </button>
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="px-4 py-2 border border-white text-white rounded-full hover:bg-primary hover:border-transparent"
                >
                  Submit
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Contact;
