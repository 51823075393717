import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import ourWork1 from "../../assets/images/ourWork1.webp";
import ourWork2 from "../../assets/images/ourWork2.webp";
import ourWork3 from "../../assets/images/ourWork3.webp";
import ourWork4 from "../../assets/images/ourWork4.webp";
import ourWork5 from "../../assets/images/ourWork5.webp";

// Import Swiper styles
import "swiper/css";

const OurWork = () => {
  const imgs = [ourWork1, ourWork2, ourWork3, ourWork4, ourWork5];

  return (
    <div className="w-full flex flex-col justify-center items-center py-6 sm:py-20 bg-[#212121]">
      <h2 className="kalnia-regular text-3xl mb-4 sm:mb-10 text-white uppercase">
        Our Work
      </h2>
      <div className="w-full max-w-screen-2xl py-4 sm:py-0">
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          autoplay={true}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2.5,
            },
          }}
          slidesPerView={2.5}
          spaceBetween={30}
          loop={true}
        >
          {imgs.map((img, index) => (
            <SwiperSlide key={index}>
              <img src={img} alt="" className="h-96 w-full object-cover" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default OurWork;
