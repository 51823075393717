import React, { useState } from "react";
import { motion } from "framer-motion";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import img1 from "../../assets/images/heroimg1.webp";
import img2 from "../../assets/images/heroimg2.webp";
import img3 from "../../assets/images/heroimg3.webp";
import bgimg from "../../assets/images/bgImg1.webp";
import mobileBgImg from "../../assets/images/mobileBgImg.webp";
import { useNavigate } from "react-router-dom";

type ImageData = {
  id: number;
  src: string;
  description: string;
};

const images: ImageData[] = [
  {
    id: 1,
    src: img1,
    description:
      "Welcome to TanksandCovers.com. Explore our range of premium Tarsco Bolted Tanks and ATECO Tank Technologies.",
  },
  {
    id: 2,
    src: img2,
    description:
      "Welcome to TanksandCovers.com. Explore our range of premium Tarsco Bolted Tanks and ATECO Tank Technologies.",
  },
  {
    id: 3,
    src: img3,
    description:
      "Welcome to TanksandCovers.com. Explore our range of premium Tarsco Bolted Tanks and ATECO Tank Technologies.",
  },
];

const Herosection = () => {
  const [backgroundImageIndex, setBackgroundImageIndex] = useState(1);
  const [leftIndex, setLeftIndex] = useState(0);
  const [rightIndex, setRightIndex] = useState(2);
  const [isFading, setIsFading] = useState(false);
  const navigate = useNavigate()

  const handleImageClick = (clickedIndex: number) => {
    setIsFading(true);
    setTimeout(() => {
      setBackgroundImageIndex(clickedIndex);
      setIsFading(false); // Start fade-in
    }, 500);

    const newLeftIndex = (clickedIndex - 1 + images.length) % images.length;
    const newRightIndex = (clickedIndex + 1) % images.length;

    setLeftIndex(newLeftIndex);
    setRightIndex(newRightIndex);
  };

  return (
    <div
      className=" flex flex-col justify-between gap-4 sm:h-screen bg-cover bg-center transition-all duration-1000 px-8 py-12 sm:px-24 sm:py-16"
      style={{ backgroundImage: `url(${images[backgroundImageIndex].src})` }}
    >
      <div>
        <motion.h1
          initial={{ x: 200, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.8, type: "spring", stiffness: 200 }}
          className="text-3xl sm:text-7xl kalnia-regular z-10 "
        >
          YOUR GO-TO EXPERTS FOR
        </motion.h1>
        <motion.h1
          initial={{ x: 200, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{
            duration: 0.8,
            delay: 0.3,
            type: "spring",
            stiffness: 200,
          }}
          className="text-4xl lg:text-7xl kalnia-regular z-10 sm:text-right"
        >
          TANKS AND COVERS.
        </motion.h1>
      </div>

      <motion.p
        initial={{ x: -200, opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        transition={{
          duration: 0.8,
          delay: 0.6,
          type: "spring",
          stiffness: 200,
        }}
        className="text-base poppins-regular sm:w-[40%]"
      >
      </motion.p>

      <div className="flex flex-col justify-center items-center mt-20 sm:mt-0">
        <div className="items-center justify-center z-30 p-2 border rounded-full -mb-8 sm:-mb-12">
          <button onClick={() => navigate('/products')} className="sm:size-44 h-20 bg-white rounded-full flex items-center justify-center text-sm sm:text-lg font-medium hover:scale-110 break-words w-[80px] transition-all duration-300 hover:bg-[#FF4A23] hover:text-white">
            LEARN MORE
          </button>
        </div>
        <div className="w-full relative">
          <img src={bgimg} className="w-full hidden sm:block" alt="bg" />
          <img src={mobileBgImg} className="w-full block sm:hidden" alt="bg" />

          <motion.div
            onClick={() => handleImageClick(leftIndex)}
            className="absolute left-[5%] sm:left-[3%] bottom-4 sm:bottom-5 xl:bottom-12"
          >
            <div className="flex gap-6 cursor-pointer">
              <motion.img
                src={images[leftIndex].src}
                initial={{ opacity: 1 }}
                animate={{ opacity: isFading ? 0.2 : 1 }}
                transition={{ duration: 0.5 }}
                alt="Previous Project"
                className="object-cover w-[4.5rem] h-[4.5rem] sm:w-28 sm:h-28 xl:size-32 rounded-2xl ease-out transition-all duration-1000"
              />
              <p className="mt-2 text-white text-left hidden sm:block w-[250px]">
                {images[leftIndex].description}
              </p>
            </div>
          </motion.div>
          <motion.div className="absolute right-[5%] sm:right-[3%] bottom-4 sm:bottom-5 xl:bottom-12">
            <div
              className="flex gap-6 cursor-pointer"
              onClick={() => handleImageClick(rightIndex)}
            >
              <motion.img
                src={images[rightIndex].src}
                initial={{ opacity: 1 }}
                animate={{ opacity: isFading ? 0.2 : 1 }}
                transition={{ duration: 0.5 }}
                alt="Next Project"
                className="object-cover w-[4.5rem] h-[4.5rem] sm:w-28 sm:h-28 xl:size-32 rounded-2xl ease-out transition-all duration-1000"
              />
              <p className="mt-2 text-white text-left hidden sm:block w-[250px]">
                {images[rightIndex].description}
              </p>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Herosection;
