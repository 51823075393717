import React from "react";
import { motion } from "framer-motion";
import Blogs from "./Blogs";

export const BlogPage = () => {
  return (
    <div className="flex flex-col overflow-hidden mx-auto max-w-screen-2xl items-center justify-center">
      <div className="bg-[#212121] w-full overflow-hidden hover:bg-[white] hover:bg-gradient-to-l from-[#ff4a23]/50 via-[#ff4a23]/80 to-[#ff4a23] transition-all duration-200">
        <div className="max-w-screen-2xl flex mx-auto pt-10 sm:pt-24 px-6 sm:px-20">
          <motion.h1
            initial={{ x: -200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 1, type: "spring", stiffness: 100 }}
            className="text-white text-4xl sm:text-8xl kalnia-regular font-extrabold py-3"
          >
            Blog Post
          </motion.h1>
        </div>
      </div>

      <div className="relative w-full h-[70px] sm:h-[110px] sm:bg-[#ff4a23]">
        <div className="sm:hidden flex h-full items-center mx-4 mt-4">
          <motion.h1 className="text-2xl lg:text-5xl kalnia-regular font-semibold">
            Tanks
          </motion.h1>
        </div>
        <div className="hidden sm:block absolute bottom-0 h-full sm:w-[90%] bg-white transform -skew-x-12 origin-left px-2">
          <motion.h1
            initial={{ x: 200, opacity: 0, skewX: 12 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="text-3xl lg:text-4xl kalnia-regular font-semibold flex items-end h-full sm:pl-32 skew-x-12"
          >
            Newest On the Blog
          </motion.h1>
        </div>
      </div>
      <Blogs />
    </div>
  );
};
