import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import img1 from "../../assets/images/productswiper1.webp";
import img2 from "../../assets/images/productswiper2.webp";
import img3 from "../../assets/images/productswiper3.webp";
import img4 from "../../assets/images/ourWork4.webp";
import img5 from "../../assets/images/productswiper5.webp";
import img6 from "../../assets/images/productswiper6.webp";
import left from "../../assets/images/left.webp";
import right from "../../assets/images/right.webp";
import arrow from "../../assets/images/arrow.svg";
import "swiper/css";
import "swiper/css/pagination";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";

type swiperProps = {
  img: string;
  title: string;
  text: string;
};

const items: swiperProps[] = [
  {
    img: img1,
    title: "Tank Foundations",
    text: "Tank foundations are crucial because they provide the necessary structural support to hold the weight of a tank, ensuring its stability and longevity by distributing the load evenly across the ground, preventing damage from uneven settling, and withstanding external forces like wind or seismic activity, ultimately safeguarding the tank's integrity over time. It is critical that you choose a company that has a demonstrated track record of successful installations when considering your next tank; a poorly designed foundation can lead to leaks, structural failure, and potential environmental hazards",
  },
  {
    img: img4,
    title: "Epoxy-Coated Bolted Tanks",
    text: "Epoxy-coated bolted tanks are durable and versatile storage solutions, designed for quick assembly. Their steel sections are bolted together and coated with epoxy to resist corrosion, making them suitable for storing a wide range of liquids including water and chemicals. Their design allows for easy expansion or relocation, and the epoxy coating ensures cleanliness, making them ideal for potable water storage. They are cost-effective and meet industry standards, making them a preferred choice for various storage needs.",
  },
  {
    img: img3,
    title: "Stainless-Steel Bolted Tanks",
    text: "Stainless steel bolted tanks are a reliable and durable solution for storing various types of liquids and materials. They are made from high-quality stainless steel panels that are bolted together, creating a tight seal that can withstand harsh conditions. The primary reasons someone might opt for a stainless steel bolted tank include its resistance to corrosion, making it a perfect choice for storing chemicals, water, and other corrosive substances. Additionally, these tanks offer flexibility in size and shape, can be easily expanded if needed, and are relatively easy to install and maintain. Their longevity and minimal maintenance requirements make them a cost-effective solution for long-term storage needs.",
  },
  {
    img: img5,
    title: "Anaerobic Digesters",
    text: "Anaerobic digestion is widely used as a source of renewable energy. The process produces a biogas consisting of methane, carbon dioxide, hydrogen sulfide and traces of other “contaminant” gases. This biogas can be used directly in combined heat and power gas engines or upgraded to natural gas quality bio methane. The nutrient rich digestate that is also produced can be used as fertilizer.",
  },
  {
    img: img2,
    title: "Flat Panel Design ",
    text: "Our panels are coated in our factory using a thermal fusion bonded powder process. We employ epoxy powder coating techniques that provide outstanding coverage and ensure long-lasting durability for field service. This electrostatically applied powder coating is thermally cured in a controlled factory setting, resulting in minimal emissions and environmentally friendly manufacturing. Tanks are constructed from the ground level using a jacking system, enhancing safety at the construction site. This approach eliminates the need for costly cranes and allows us to work efficiently in confined spaces, offering the safest and most cost-effective construction in the industry. With decades of experience in tank sales and construction, we understand that quality and value are non-negotiable. That's why thousands of companies around the globe trust us.",
  },
  {
    img: img6,
    title: "Unmatched Capabilities",
    text: "We are leading the industry in engineering, manufacturing, and construction of storage tank systems. Our goal is to provide you with the best value and the most advanced storage tank solution in the industry.",
  },
];

const ProductSwiper = () => {
  return (
    <div className="mx-auto max-w-screen-2xl w-full">
      <div className="sm:hidden w-full">
        <div className="flex gap-4 justify-end w-full -mt-16 pb-4 px-4">
          <button className="px-3 py-4 prev-btn flex justify-center items-center rounded-full border border-black">
            <img src={left} className="w-4" alt="Previous" />
          </button>
          <button className="px-3 py-4 next-btn flex justify-center items-center rounded-full border border-black">
            <img src={right} className="w-4" alt="Next" />
          </button>
        </div>
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          className="flex justify-center items-center "
          spaceBetween={10}
          breakpoints={{
            640: {
              slidesPerView: 1.3,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 4,
            },
          }}
          navigation={{
            nextEl: ".next-btn",
            prevEl: ".prev-btn",
          }}
          loop
        >
          {items.map((item, index) => (
            <SwiperSlide
              className="flex justify-end group transition-all duration-500 items-end z-10"
              key={index}
            >
              <div
                className="flex items-end relative w-full h-[350px] bg-cover "
                style={{ backgroundImage: `url(${item.img})` }}
              >
                <div className="px-2 py-2  bg-gradient-to-t from-black/80 via-black/30 to-black/0 w-full h-full ">
                  <div className="absolute bottom-20 w-[50%] h-[8px]">
                    <div className="h-2 w-20 bg-[#ff4a23]"></div>
                    <div className="kalnia-regular  text-xl text-white z-20 w-full">
                      <div className="flex flex-col-reverse gap-2 justify-between mb-10">
                        {item.title}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <div className="w-full z-20 poppins-regular text-sm px-4">
                  {item.text}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="hidden sm:flex w-full">
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          className="flex justify-center items-center "
          spaceBetween={10}
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 4,
            },
          }}
          navigation={{
            nextEl: ".next-btn",
            prevEl: ".prev-btn",
          }}
          loop
        >
          {items.map((item, index) => (
            <SwiperSlide
              className="flex justify-end group transition-all duration-500 items-end z-10"
              key={index}
            >
              <div
                className="flex items-end relative w-full h-[350px] bg-cover "
                style={{ backgroundImage: `url(${item.img})` }}
              >
                <div className="px-2 py-2 group-hover:p-0 bg-gradient-to-t from-black/80 via-black/30 to-black/0 w-full h-full group-hover:bg-gradient-to-t group-hover:from-black/0">
                  <div className="absolute bottom-20 group-hover:h-full transition-all duration-500 group-hover:w-full group-hover:bottom-0 w-[50%] h-[8px] group-hover:bg-transparent group-hover:bg-gradient-to-b group-hover:from-[#ff4a23]/90 group-hover:via-[#ff4a23]/70 group-hover:to-[#ff4a23]/20">
                    <div className="h-2 w-20 bg-[#ff4a23] group-hover:hidden"></div>
                    <div className="kalnia-regular  group-hover:bottom-auto  text-xl text-white z-20 w-full">
                      <div className="flex flex-col-reverse gap-2 justify-between group-hover:p-4 mb-10 group-hover:font-medium">
                        {item.title}
                        <div className="flex justify-end">
                          <img
                            src={arrow}
                            className="hidden group-hover:block  cursor-pointer "
                            alt=""
                          />
                        </div>
                      </div>
                      <div>
                        <div className="absolute hidden group-hover:block w-full z-20 poppins-regular h-[180px] text-base px-4 overflow-y-scroll">
                          {item.text}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="hidden sm:flex gap-6 justify-end pt-6 pr-24">
        <button className="px-4 py-7 flex prev-btn justify-center bg-white hover:bg-primary hover:border-none border border-black group btn-disabled disabled:bg-white disabled:border w-16 h-16 items-center rounded-full disabled:border-black">
          <FaArrowLeftLong className="text-2xl text-black group-hover:text-white" />
        </button>
        <button className="px-4 py-7 group flex next-btn justify-center border hover:border-none border-black bg-white hover:bg-primary btn-disabled disabled:bg-white disabled:border w-16 h-16 items-center rounded-full disabled:border-black">
          <FaArrowRightLong className="text-2xl group-hover:text-white text-black" />
        </button>
      </div>
    </div>
  );
};

export default ProductSwiper;
