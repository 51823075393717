import React from "react";
import img from "../../assets/images/resourceimg2.svg";
import { motion } from "framer-motion";

const Engineers = () => {
  return (
    <div className="max-w-screen-2xl mx-auto pb-6 sm:pb-24">
      <div className="flex justify-between flex-col sm:flex-row">
        <motion.img
          src={img}
          alt=""
          className="hidden sm:block sm:w-[45%] z-10"
        />

        <div className="sm:w-[65%]">
          <div className="items-center border-b">
            <div className="hidden sm:flex relative w-full h-[110px] bg-[#ff4a23]">
              <div className="absolute bottom-0 right-24 h-full w-[90%] bg-white transform -skew-x-12 origin-left px-2">
                <h1 className="text-4xl lg:text-5xl kalnia-regular font-semibold flex items-center h-full pl-8 skew-x-12">
                  Engineers
                </h1>
              </div>
            </div>
          </div>

          <div className="sm:hidden flex mt-6 mb-4 mx-4">
            <h1 className="text-4xl lg:text-5xl kalnia-regular font-semibold flex items-center h-full">
              Engineers
            </h1>
          </div>
          <div className="w-full sm:pr-48">
            <p className="px-4 sm:px-8 mt-4 sm:mt-20 poppins-regular text-base mb-6">
              Here you will find work that we hope will inform your own research
              and projects and serve to inspire and guide you on your journey.
            </p>
            <div>
              <div className="group border-b py-9 px-8 hover:bg-[#212121] hover:text-white flex justify-between">
                Foundation
                <div className="flex gap-6">
                  <a href="/foundation1.PDF" className="group" target="_blank">
                    <svg
                      width="16"
                      height="23"
                      viewBox="0 0 16 23"
                      xmlns="http://www.w3.org/2000/svg"
                      className="stroke-current group-hover:stroke-white hover:!stroke-primary group-hover:text-primary"
                    >
                      <path
                        d="M1 22H15M8 1V17.3333M8 17.3333L13.8333 11.5M8 17.3333L2.16667 11.5"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </a>

                  <a href="/foundation2.pdf" target="_blank">
                    <svg
                      width="16"
                      height="23"
                      viewBox="0 0 16 23"
                      fill="none"
                      className="stroke-current group-hover:stroke-white hover:!stroke-primary group-hover:text-primary"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 22H15M8 1V17.3333M8 17.3333L13.8333 11.5M8 17.3333L2.16667 11.5"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </a>
                </div>
              </div>

              <div className="group border-b py-9 px-8 hover:bg-[#212121] hover:text-white flex justify-between">
                Tanks
                <div className="flex gap-6">
                  <a href="/tanks1.pdf" target="_blank">
                    <svg
                      width="16"
                      height="23"
                      viewBox="0 0 16 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="stroke-current group-hover:stroke-white hover:!stroke-primary group-hover:text-primary"
                    >
                      <path
                        d="M1 22H15M8 1V17.3333M8 17.3333L13.8333 11.5M8 17.3333L2.16667 11.5"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </a>
                  <a href="/tanks2.pdf" target="_blank">
                    <svg
                      width="16"
                      height="23"
                      viewBox="0 0 16 23"
                      fill="none"
                      className="stroke-current group-hover:stroke-white hover:!stroke-primary group-hover:text-primary"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 22H15M8 1V17.3333M8 17.3333L13.8333 11.5M8 17.3333L2.16667 11.5"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </a>
                </div>
              </div>

              <div className="group border-b py-9 px-8 hover:bg-[#212121] hover:text-white flex justify-between">
                Covers
                <div className="flex gap-6">
                  <a href="/covers1.pdf" target="_blank">
                    <svg
                      width="16"
                      height="23"
                      viewBox="0 0 16 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="stroke-current group-hover:stroke-white hover:!stroke-primary group-hover:text-primary"
                    >
                      <path
                        d="M1 22H15M8 1V17.3333M8 17.3333L13.8333 11.5M8 17.3333L2.16667 11.5"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </a>
                  <a href="/covers2.pdf" target="_blank">
                    <svg
                      width="16"
                      height="23"
                      viewBox="0 0 16 23"
                      fill="none"
                      className="stroke-current group-hover:stroke-white hover:!stroke-primary group-hover:text-primary"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 22H15M8 1V17.3333M8 17.3333L13.8333 11.5M8 17.3333L2.16667 11.5"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="group border-b py-9 px-8 hover:bg-[#212121] hover:text-white flex justify-between">
                Specifications
                <div className="flex gap-6">
                  <a href="/covers1.pdf" target="_blank">
                    <svg
                      width="16"
                      height="23"
                      viewBox="0 0 16 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="stroke-current group-hover:stroke-white hover:!stroke-primary group-hover:text-primary"
                    >
                      <path
                        d="M1 22H15M8 1V17.3333M8 17.3333L13.8333 11.5M8 17.3333L2.16667 11.5"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </a>
                  <a href="/specification2.docx" target="_blank">
                    <svg
                      width="16"
                      height="23"
                      viewBox="0 0 16 23"
                      fill="none"
                      className="stroke-current group-hover:stroke-white hover:!stroke-primary group-hover:text-primary"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 22H15M8 1V17.3333M8 17.3333L13.8333 11.5M8 17.3333L2.16667 11.5"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Engineers;
