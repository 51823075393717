import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import tarsoImg1 from "../../assets/images/tarsco.svg";
import sec1img1 from "../../assets/images/expSectionImg.webp";
import sec1img2 from "../../assets/images/sec1img2.webp";
import sec1img3 from "../../assets/images/sec1img3.webp";
import sec1img4 from "../../assets/images/sec1img4.webp";
import { motion } from "framer-motion";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";

interface ProductCard {
  title: string;
  image: string;
  alt: string;
}

const TarscoSection = () => {
  const products: ProductCard[] = [
    {
      title: "Shop Welded & Field Welded Tanks",
      image: sec1img1,
      alt: "Aerial view of multiple dome-topped welded tanks",
    },
    {
      title: "Epoxy-Coated Bolted Tanks",
      image: sec1img2,
      alt: "Close-up of white epoxy-coated bolted tank surface",
    },
    {
      title: "Stainless-Steel Bolted Tanks",
      image: sec1img3,
      alt: "Interior view of stainless steel tank with yellow safety railings",
    },
    {
      title: "Foundation Designs & Installation",
      image: sec1img4,
      alt: "Workers installing tank foundation equipment",
    },
  ];

  return (
    <div className="max-w-screen-2xl w-full bg-white py-12">
      {/* Header Section */}
      <div className="max-w-screen-2xl mx-auto mb-12 flex flex-col md:flex-row items-start gap-8">
        <div className="sm:pl-20 py-6 pr-10 h-40 bg-[#EDEDED] border border-black rounded-r-full z-10">
          <motion.img
            initial={{ x: -200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 1 }}
            src={tarsoImg1}
            className="w-full h-32"
            alt="Tarsco Logo"
          />
        </div>

        <div className="flex-1 flex flex-col px-4 sm:px-0 space-y-2 md:flex-row justify-between space-x-2 sm:mr-14">
          <motion.h1
            initial={{ x: -200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 0.5,
              delay: 0.5,
            }}
            className="text-4xl font-serif w-full"
          >
            Your premier provider for Epoxy-Coated and Stainless-Steel Bolted
            Tanks
          </motion.h1>
          <motion.p
            initial={{ x: 200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 0.5,
              delay: 1,
            }}
            className="text-gray-600 w-full max-w-screen-xl"
          >
            Tarsco Bolted Tank is a leading manufacturer of epoxy-coated and
            stainless-steel bolted tanks, serving various industries like water,
            wastewater, and dry bulk storage. Known for quality, durability, and
            tailored solutions, they provide reliable storage options with
            state-of-the-art technology and expert craftsmanship.
          </motion.p>
        </div>
      </div>

      {/* Products Grid */}
      <div className="max-w-7xl hidden mx-auto md:grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {products.map((product, index) => (
          <div key={index} className="flex flex-col">
            <div className="relative overflow-hidden mb-4 mx-4 sm:mx-0 h-[400px]">
              <img
                src={product.image}
                alt={product.alt}
                className="object-cover w-full h-full hover:scale-110 transition-transform duration-300"
              />
            </div>
            <h3 className="text-xl font-medium text-center">{product.title}</h3>
          </div>
        ))}
      </div>
      <div className="w-full flex mx-auto justify-center items-center md:hidden max-w-screen-2xl">
        <div className="flex">
          <button className="px-2 py-3 flex prev-btn justify-center bg-[#FF4A23] btn-disabled disabled:bg-white disabled:border w-10 h-10 items-center rounded-full disabled:border-black">
            <FaArrowLeftLong className="text-3xl text-white" />
          </button>
        </div>
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          autoplay={true}
          navigation={{
            nextEl: ".next-btn",
            prevEl: ".prev-btn",
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2.5,
            },
          }}
          slidesPerView={2.5}
          spaceBetween={30}
          loop
        >
          {products.map((product, index) => (
            <SwiperSlide key={index} className="">
              <div key={index} className="flex flex-col">
                <div className="relative overflow-hidden mb-4 mx-4 sm:mx-0 h-[300px]">
                  <img
                    src={product.image}
                    alt={product.alt}
                    className="object-cover w-full h-full hover:scale-110 transition-transform duration-300"
                  />
                </div>
                <h3 className="text-xl font-medium text-center">
                  {product.title}
                </h3>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="flex">
          <button className="px-2 py-3 flex next-btn justify-center bg-[#FF4A23] btn-disabled disabled:bg-white disabled:border w-10 h-10 items-center rounded-full disabled:border-black">
            <FaArrowRightLong className="text-lg text-white" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TarscoSection;
