import React from "react";
import { Field } from "formik";
import FormikErrorMessage from "./ErrorField";

interface FormikFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  className?: string;
  label?: string;
  placeholder?: string;
}

const FormikField = ({
  name,
  label,
  className,
  placeholder,
  ...props
}: FormikFieldProps) => {
  return (
    <div className="mb-4 w-full">
      {label && (
        <label
          htmlFor={name}
          className="block mb-1 text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      )}
      <Field
        id={name}
        name={name}
        placeholder={placeholder}
        className={`w-full sm:w-[400px] px-3 py-2 border border-gray-300 inter-regular rounded-lg focus:ring-1 active:ring-0 focus:outline-none focus:ring-blue-500 ${className}`}
        {...props}
      />
      <FormikErrorMessage name={name} />
    </div>
  );
};

export default FormikField;
