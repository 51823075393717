import React, { useRef } from "react";
import video from "../../assets/videos/video.webm";
import { motion, MotionValue, useScroll, useTransform } from "framer-motion";

function useParallax(value: MotionValue<number>, distance: number) {
  return useTransform(value, [1, 0], [-distance, distance]);
}

const VideoSection = () => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["end end", "end start"],
  });
  const y = useParallax(scrollYProgress, 200);

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <div className="flex flex-col justify-end items-end">
        {/* <hr className="w-[40%] border border-black" /> */}
        <div
          ref={ref}
          className="flex flex-col sm:flex-row max-w-screen-2xl mx-4 sm:my-6 sm:px-10 mb-10 sm:mb-20 sm:mx-8 gap-4 items-center justify-center"
        >
          <motion.div
            style={{ y }}
            className="flex flex-col justify-center items-center sm:-mt-80 mb-10 sm:mb-0"
          >
            <h2 className="text-3xl sm:text-[80px] leading-[1] font-bold mb-2 kalnia-regular">
              Our tanks in action
            </h2>
            <p className="poppins-regular leading-5 text-base text-[#3A3A3A]">
              Tarsco Bolted Tank is a leading manufacturer of epoxy-coated and
              stainless-steel bolted tanks, serving various industries like
              water, wastewater, and dry bulk storage. Known for quality,
              durability, and tailored solutions, they provide reliable storage
              options with state-of-the-art technology and expert craftsmanship.
            </p>
          </motion.div>
          <video
            src={video}
            controls
            muted
            loop
            autoPlay
            className="max-w-[800px] w-full sm:mr-20 sm:mt-20"
          />
        </div>
      </div>
    </div>
  );
};

export default VideoSection;
