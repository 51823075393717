import React from "react";
import Connect from "../HomePage/Connect";
import GetInTouch from "./GetInTouch";
import { motion } from "framer-motion";

const ContactPage = () => {
  return (
    <div className="overflow-hidden">
      <div className="bg-[#212121] hover:bg-[white] hover:bg-gradient-to-l from-[#ff4a23]/50 via-[#ff4a23]/80 to-[#ff4a23] transition-all duration-200">
        <div className="max-w-screen-2xl flex mx-auto pt-10 sm:pt-24 px-6 sm:px-20">
          <motion.h1
            initial={{ x: -200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 1, type: "spring", stiffness: 100 }}
            className="text-white text-4xl sm:text-8xl kalnia-regular font-extrabold py-3"
          >
            Contact Us
          </motion.h1>
        </div>
      </div>
      <GetInTouch />
      <Connect />
    </div>
  );
};

export default ContactPage;
