import React from "react";
import { useNavigate } from "react-router-dom";
import img from "../../assets/images/requestimg1.webp";
import { motion } from "framer-motion";

const Question = () => {
  const navigate = useNavigate();

  return (
    <div className="max-w-screen-2xl overflow-hidden mx-auto sm:py-16">
      <div className="w-full flex flex-col sm:flex-row">
        <div className="sm:w-[60%] sm:-skew-x-[19deg] bg-[#ff4a23] px-4 py-10 sm:pt-20 sm:b-60 sm:pl-32 pr-14 sm:-ml-[90px]">
          <div className="sm:skew-x-[19deg] flex flex-col justify-center gap-6">
            <h2 className="kalnia-regular font-semibold text-4xl lg:text-5xl text-white">
              Have any question ?
            </h2>
            <h2 className="kalnia-bold text-4xl lg:text-5xl text-white">
              {" "}
              Our expert can help
            </h2>
            <p className="poppins-regular text-white">
              Tarsco Bolted Tank is a leading manufacturer of epoxy-coated and
              stainless-steel bolted tanks, serving various industries like
              water, wastewater, and dry bulk storage.{" "}
            </p>
            <motion.button
              onClick={() => {
                window.scrollTo(0, 0);
                navigate("/contact-us");
              }}
              className="py-2 px-5 mt-6 bg-[#ff4a23] max-w-[140px] border border-white rounded-full text-white hover:bg-white hover:text-black transition-all duration-300"
            >
              Contact us
            </motion.button>
          </div>
        </div>
        <div className="flex items-end">
          <div className="relative sm:h-[85%] w-full">
            <img src={img} alt="" className="h-full w-full" />
            <div className="bg-[#ff4a23] hidden sm:block w-56 h-36 -skew-x-[19deg] absolute top-0 right-0 -mr-32"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Question;
