import React from "react";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import tarscoImg2 from "../../assets/images/tarscoImg2.svg";
import sec2img1 from "../../assets/images/sec2img1.webp";
import sec2img2 from "../../assets/images/sec2img2.webp";
import sec2img3 from "../../assets/images/sec2img3.webp";
import sec2img4 from "../../assets/images/sec2img4.webp";
import { motion } from "framer-motion";

interface ProductCard {
  image: string;
  alt: string;
}

const TarscoSection2 = () => {
  const products: ProductCard[] = [
    {
      image: sec2img1,
      alt: "Aerial view of multiple dome-topped welded tanks",
    },
    {
      image: sec2img2,
      alt: "Close-up of white epoxy-coated bolted tank surface",
    },
    {
      image: sec2img3,
      alt: "Interior view of stainless steel tank with yellow safety railings",
    },
    {
      image: sec2img4,
      alt: "Workers installing tank foundation equipment",
    },
  ];

  return (
    <div className="w-full bg-white py-12">
      {/* Header Section */}
      <div className="max-w-screen-2xl mx-auto mb-12 flex flex-col items-center gap-8">
        <div className="flex w-full flex-col mx-4 justify-between items-start md:flex-row gap-4 sm:gap-10">
          <div className="pl-14 flex items-center pr-10 py-10 bg-[#EDEDED] border border-black rounded-r-full">
            <motion.img
              initial={{ x: -200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 1 }}
              src={tarscoImg2}
              className="w-full"
              alt="Tarsco Logo"
            />
          </div>
          <motion.h1
            initial={{ x: -200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 0.5,
              delay: 0.5,
            }}
            className="text-4xl px-4 font-serif w-full max-w-[800px] sm:mr-20"
          >
            We are your single-source Aluminum Dome, Flat Cover, and Floating
            Roof Systems provider.
          </motion.h1>
        </div>
        <div className="flex-1 flex flex-col px-4 space-y-2 md:flex-row justify-between space-x-2">
          <motion.p
            initial={{ x: 200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 0.5,
              delay: 1,
            }}
            className="text-gray-600 w-full max-w-screen-xl"
          >
            Thanks to our partnership with ATECO, we are proud to offer a
            complete range of services for aluminum domes, aluminum flat covers,
            and aluminum floating roof systems on a global scale. Our expertise
            ensures that each project is handled with the utmost care and
            precision, providing you with high-quality solutions tailored to
            your specific needs. When you choose to incorporate these products
            alongside our Tarsco Bolted Tank offerings, you can rest assured
            that you are receiving the best value and performance for your
            investment. We are committed to delivering exceptional results that
            meet and exceed your expectations, making your projects a success.
          </motion.p>
        </div>
      </div>

      {/* Products Grid */}
      <div className="max-w-7xl hidden mx-auto md:grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {products.map((product, index) => (
          <div key={index} className="flex flex-col">
            <div className="relative overflow-hidden mb-4 mx-4 sm:mx-0">
              <img
                src={product.image}
                alt={product.alt}
                className="object-cover w-full h-96 hover:scale-110 transition-transform duration-300"
              />
            </div>
          </div>
        ))}
      </div>

      <div className="w-full flex mx-auto justify-center items-center md:hidden max-w-screen-2xl">
        <div className="flex">
          <button className="px-2 py-3 flex prev-btn justify-center bg-[#FF4A23] btn-disabled disabled:bg-white disabled:border w-10 h-10 items-center rounded-full disabled:border-black">
            <FaArrowLeftLong className="text-3xl text-white" />
          </button>
        </div>
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          autoplay={true}
          navigation={{
            nextEl: ".next-btn",
            prevEl: ".prev-btn",
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2.5,
            },
          }}
          slidesPerView={2.5}
          spaceBetween={30}
          loop
        >
          {products.map((product, index) => (
            <SwiperSlide key={index} className="">
              <div key={index} className="flex flex-col">
                <div className="relative overflow-hidden mb-4 mx-4 sm:mx-0 h-[300px]">
                  <img
                    src={product.image}
                    alt={product.alt}
                    className="object-cover w-full h-full hover:scale-110 transition-transform duration-300"
                  />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="flex">
          <button className="px-2 py-3 flex next-btn justify-center bg-[#FF4A23] btn-disabled disabled:bg-white disabled:border w-10 h-10 items-center rounded-full disabled:border-black">
            <FaArrowRightLong className="text-lg text-white" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TarscoSection2;
