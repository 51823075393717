import React from "react";
import location from "../../assets/images/locationicon.webp";
import phone from "../../assets/images/phoneicon.webp";
import contact from "../../assets/images/mail.svg";

const GetInTouch = () => {
  return (
    <div className=" bg-[#1D0500] overflow-hidden ">
      <div className="max-w-screen-2xl mx-auto px-4 sm:px-6 lg:px-20 pt-12 pb-20 flex flex-col mt-[64px] sm:mt-[72px]">
        <h2 className="text-white kalnia-regular font-bold text-4xl mb-4">
          Get in Touch
        </h2>
        <p className="poppins-regular text-[#fff] opacity-50 mb-16 w-[50%]">
          Need an expert? you are more than welcomed to leave contact info and
          we will be in touch shortly.
        </p>
        <div className="flex flex-col sm:flex-row gap-5 sm:gap-0 items-start justify-between">
          <div className="flex justify-center items-center gap-9">
            <img className="" src={location} alt="" />
            <div className="flex justify-center items-center gap-4">
              <svg
                width="1"
                height="67"
                viewBox="0 0 1 67"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  opacity="0.5"
                  x1="0.5"
                  y1="67"
                  x2="0.500003"
                  y2="-2.18557e-08"
                  stroke="url(#paint0_linear_181_232)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_181_232"
                    x1="1.5"
                    y1="67"
                    x2="1.5"
                    y2="2.18557e-08"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#212121" />
                    <stop offset="0.521" stop-color="#EC4623" />
                    <stop offset="1" stop-color="#212121" />
                  </linearGradient>
                </defs>
              </svg>
              <div className="">
                <h4 className="kalnia-regular text-white opacity-50 font-bold text-2xl mb-1">
                  VISIT US
                </h4>
                <p
                  onClick={() =>
                    window.open(
                      "https://www.google.com/maps?ll=40.821101,-81.399374&z=13&t=m&hl=en&gl=IN&mapclient=embed&q=2014+Champions+Gateway+Canton,+OH+44708+USA",
                    )
                  }
                  className="poppins-regular text-white w-[60%] cursor-pointer"
                >
                  2014 Champions Gateway Suite 301, Canton, OH 44708
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center gap-9">
            <img className="" src={phone} alt="" />
            <div className="flex justify-center items-center gap-4">
              <svg
                width="1"
                height="67"
                viewBox="0 0 1 67"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  opacity="0.5"
                  x1="0.5"
                  y1="67"
                  x2="0.500003"
                  y2="-2.18557e-08"
                  stroke="url(#paint0_linear_181_232)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_181_232"
                    x1="1.5"
                    y1="67"
                    x2="1.5"
                    y2="2.18557e-08"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#212121" />
                    <stop offset="0.521" stop-color="#EC4623" />
                    <stop offset="1" stop-color="#212121" />
                  </linearGradient>
                </defs>
              </svg>
              <div>
                <h4 className="kalnia-regular text-white opacity-50 font-bold text-2xl mb-1">
                  CALL US
                </h4>
                <p onClick={() => window.open("tel:+13302651776")} className="poppins-regular cursor-pointer text-white">+1 (330)-265-1776</p>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center gap-9">
            <img className="" src={contact} alt="" />
            <div className="flex justify-center items-center gap-4">
              <svg
                width="1"
                height="67"
                viewBox="0 0 1 67"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  opacity="0.5"
                  x1="0.5"
                  y1="67"
                  x2="0.500003"
                  y2="-2.18557e-08"
                  stroke="url(#paint0_linear_181_232)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_181_232"
                    x1="1.5"
                    y1="67"
                    x2="1.5"
                    y2="2.18557e-08"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#212121" />
                    <stop offset="0.521" stop-color="#EC4623" />
                    <stop offset="1" stop-color="#212121" />
                  </linearGradient>
                </defs>
              </svg>
              <div>
                <h4 className="kalnia-regular text-white opacity-50 font-bold text-2xl mb-1">
                  CONTACT US
                </h4>
                <a href="mailto:andy@TanksandCovers.com" className="poppins-regular text-white">
                  andy@TanksandCovers.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <svg
        className=""
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="2"
        viewBox="0 0 1247 2"
        fill="none"
      >
        <path d="M0.5 1H1246.5" stroke="url(#paint0_linear_5_150)" />
        <defs>
          <linearGradient
            id="paint0_linear_5_150"
            x1="0.5"
            y1="1.5"
            x2="1246.5"
            y2="1.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop />
            <stop offset="0.5" stop-color="white" />
            <stop offset="1" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default GetInTouch;
