import React from 'react';
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination = ({ currentPage, totalPages, onPageChange }: PaginationProps) => {
  const pages = [];

  for (let i = 1; i <= totalPages; i++) {
    pages.push(i);
  }

  return (
    <div className="flex justify-between pp-4 sm:p-6 border-t">
      <div
        onClick={() => onPageChange(currentPage - 1)}
        className='flex gap-2 justify-center items-center'>
        <FaArrowLeft
          className={`${currentPage === 1 ? "text-gray-400" : "cursor-pointer"}`}
        />
        <p
          className={`${currentPage === 1 ? "text-gray-400" : "cursor-pointer"}`}
        >
          Previous
        </p>
      </div>
      <div className='flex gap-2'>
        {pages.map(page => (
          <button
            key={page}
            onClick={() => onPageChange(page)}
            className={`${page === currentPage ? 'bg-blue-100' : ''} px-4 py-2 rounded-md`}
          >
            {page}
          </button>
        ))}
      </div>
      <div
        onClick={() => onPageChange(currentPage + 1)}
        className='flex gap-2 justify-center items-center'>
        <p
          className={`${currentPage === totalPages ? "text-gray-400" : "cursor-pointer"}`}
        >
          Next
        </p>
        <FaArrowRight
          className={`${currentPage === totalPages ? "text-gray-400" : "cursor-pointer"}`}
        />
      </div>
    </div>
  );
};

export default Pagination;
