import React from "react";
import Connect from "./Connect";
import OurProcess from "./OurProcess/OurProcess";
import OurWork from "./OurWork";
import TarscoSection from "./TarscoSection";
import TarscoSection2 from "./TarscoSection2";
import VideoSection from "./VideoSection";
import Herosection from "./HeroSection";
import ChoiceSlider from "./ChoiceSlider";
import Questions from "./Questions";

const HomePage = () => {
  return (
    <div className="overflow-hidden">
      <Herosection />
      <div className="flex flex-col overflow-hidden mx-auto max-w-screen-2xl items-center justify-center">
        <TarscoSection />
      </div>
      <OurProcess />
      <TarscoSection2 />
      <OurWork />
      {/* <ChoiceSlider /> */}
      <VideoSection />
      <Questions />
      <Connect />
    </div>
  );
};

export default HomePage;
