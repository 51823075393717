import React from "react";
import img from "../../assets/images/resourceheroimg.svg";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const Covers = () => {
const navigate = useNavigate()

  const variants = {
    hidden: { x: -100, opacity: 0 },
    visible: { x: 0, opacity: 1, transition: { duration: 0.5, delay: 1 } },
    hover: {
      scale: [1, 1.1, 1, 1.1, 1, 1.1, 1, 1.1, 1],
      transition: { duration: 2 },
    },
  };
  return (
    <div className="max-w-screen-2xl mx-auto py-6 sm:px-0 sm:py-16">
      <div className="flex justify-between flex-col-reverse sm:flex-row">
        <div className="sm:w-[65%]">
          <div className="items-center border-b mt-4">
            <div className="relative w-full h-[70px] sm:h-[110px] bg-[#ff4a23]">
              <div className="sm:hidden absolute flex items-center bottom-0 left-24 h-full sm:w-[90%] bg-white transform -ml-20 sm:-ml-4 skew-x-12 origin-left px-4">
                <motion.h1
                  initial={{ x: -100, opacity: 0, skewX: -12 }}
                  whileInView={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  className="text-2xl border-b lg:text-5xl kalnia-regular font-semibold -skew-x-12"
                >
                  Covers
                </motion.h1>
              </div>
              <div className="hidden sm:block absolute bottom-0 left-28 h-full w-[90%] bg-white transform skew-x-12 origin-left px-2">
                <motion.h1
                  initial={{ x: -200, opacity: 0, skewX: -12 }}
                  whileInView={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  className="text-4xl border-b lg:text-5xl kalnia-regular font-semibold flex items-center h-full pl-12 -skew-x-12"
                >
                  Covers
                </motion.h1>
              </div>
            </div>
          </div>
          <div className="w-full sm:pr-48">
            <motion.p
              initial={{ x: -200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.5 }}
              className="sm:px-8 mt-4 sm:mt-16 poppins-regular px-4 text-sm sm:text-base mb-4 sm:mb-6"
            >
              Ateco Tank Technologies specializes in Aluminum Geodesic domes and
              covers known for their strength and durability. These structures
              enhance efficiency and safety while also being aesthetically
              pleasing. Committed to quality and customer satisfaction, Ateco
              offers top solutions for reliable storage needs.
            </motion.p>
            <motion.button
              variants={variants}
              initial="hidden"
              whileInView="visible"
              whileHover="hover"
              onClick={() => {
                window.scrollTo(0, 0);
                navigate("/contact-us");
              }}
              className="py-2 px-5 bg-[#ff4a23] rounded-full text-white ml-4 sm:ml-8"
            >
              Contact us
            </motion.button>
          </div>
        </div>
        <div className="sm:w-[45%] z-10 relative overflow-hidden">
          <div className="sm:pb-12">
            <motion.img
              initial={{ x: 200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 1 }}
              src={img}
              alt=""
              className=" w-full"
            />
            <div className="hidden sm:block absolute bottom-0 w-[150px] h-[220px] bg-[#ff4a23] -skew-x-12 -right-20"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Covers;
