import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import img1 from "../../assets/images/productimg3.svg";
import left from "../../assets/images/left.webp";
import right from "../../assets/images/right.webp";
import img2 from "../../assets/images/productimg4.svg";
import img3 from "../../assets/images/productimg5.webp";
import arrow from "../../assets/images/arrow.svg";

type CardProps = {
  img: string;
  title: string;
  text: string;
};

const items: CardProps[] = [
  {
    img: img1,
    title: "Aluminum Geodesic Domes",
    text: "Aluminum geodesic domes are increasingly utilized as roof systems for storage tanks due to their strength and lightweight properties. The triangular design provides excellent structural integrity, allowing these domes to withstand harsh weather conditions while minimizing material use. Their corrosion-resistant aluminum construction ensures durability and longevity, making them an ideal choice for various storage applications. Additionally, the efficient design helps in reducing maintenance costs and improving overall storage efficiency.",
  },
  {
    img: img3,
    title: "Aluminum Flat Covers",
    text: "Aluminum flat covers are commonly used in wastewater treatment plants due to their lightweight, corrosion-resistant properties and ease of installation. These covers serve multiple purposes, including preventing contamination from external elements, minimizing odors, and enhancing safety by restricting access to hazardous areas. Additionally, aluminum flat covers help reduce evaporation, maintain temperature control, and improve overall operational efficiency in the treatment processes. Their durability and low maintenance requirements make them an ideal choice for facilities managing wastewater.",
  },
  {
    img: img2,
    title: "Aluminum Floating Roof Systems",
    text: "Aluminum floating roof systems are designed to minimize evaporation and reduce emissions in storage tanks. These roofs float on the liquid surface, adapting to changes in liquid levels, which helps to prevent contamination and maintain product quality. Purchasing an aluminum floating roof system can lead to significant cost savings by reducing product loss and improving safety. Additionally, they are durable and require less maintenance compared to other materials, making them a smart investment for tank owners. Furthermore, their lightweight nature facilitates easier installation and handling. Their eco-friendly design also contributes positively to environmental protection efforts. ",
  },
];

const Card = () => {
  return (
    <div className="mx-auto max-w-screen-2xl sm:pb-24">
      <div className="sm:hidden w-full">
        <div className="flex gap-4 justify-end w-full -mt-16 pb-4 px-4">
          <button className="px-3 py-4 prev-btn flex justify-center items-center rounded-full border border-black">
            <img src={left} className="w-4" alt="Previous" />
          </button>
          <button className="px-3 py-4 next-btn flex justify-center items-center rounded-full border border-black">
            <img src={right} className="w-4" alt="Next" />
          </button>
        </div>
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          className="flex justify-center items-center "
          spaceBetween={10}
          breakpoints={{
            640: {
              slidesPerView: 1.3,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 4,
            },
          }}
          navigation={{
            nextEl: ".next-btn",
            prevEl: ".prev-btn",
          }}
          loop
        >
          {items.map((item, index) => (
            <SwiperSlide
              className="flex justify-end group transition-all duration-500 items-end z-10"
              key={index}
            >
              <div
                className="flex items-end relative w-full h-[380px] bg-cover "
                style={{ backgroundImage: `url(${item.img})` }}
              >
                <div className="px-2 py-2 group-hover:p-0 bg-gradient-to-t from-black/80 via-black/30 to-black/0 w-full h-full group-hover:bg-gradient-to-t group-hover:from-black/0">
                  <div className="absolute bottom-20 group-hover:h-full transition-all duration-500 group-hover:w-full group-hover:bottom-0 w-[60%] h-[8px]  group-hover:bg-transparent group-hover:bg-gradient-to-b group-hover:from-[#ff4a23]/90 group-hover:via-[#ff4a23]/70 group-hover:to-[#ff4a23]/20">
                    <div className="h-2 w-20 bg-[#ff4a23] group-hover:hidden"></div>
                    <div className="kalnia-regular  group-hover:bottom-auto  text-xl text-white z-20 w-full">
                      <div className="flex flex-col-reverse gap-2 justify-between group-hover:p-4 mb-10 group-hover:font-medium">
                        {item.title}
                        <div className="flex justify-end">
                          <img
                            src={arrow}
                            className="hidden group-hover:block cursor-pointer"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="absolute hidden opacity-0 group-hover:opacity-100 group-hover:block w-full z-20 poppins-regular text-base px-4 transition-all duration-500 h-[180px] overflow-y-scroll">
                        {item.text}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="hidden overflow-hidden sm:grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 px-24">
        {items.map((item, index) => (
          <div
            className="flex justify-end group transition-all duration-500 items-end z-10"
            key={index}
          >
            <div
              className="flex items-end relative w-full h-[380px] bg-cover "
              style={{ backgroundImage: `url(${item.img})` }}
            >
              <div className="px-2 py-2 group-hover:p-0 bg-gradient-to-t from-black/80 via-black/30 to-black/0 w-full h-full group-hover:bg-gradient-to-t group-hover:from-black/0">
                <div className="absolute bottom-20 group-hover:h-full transition-all duration-500 group-hover:w-full group-hover:bottom-0 w-[60%] h-[8px]  group-hover:bg-transparent group-hover:bg-gradient-to-b group-hover:from-[#ff4a23]/90 group-hover:via-[#ff4a23]/70 group-hover:to-[#ff4a23]/20">
                  <div className="h-2 w-20 bg-[#ff4a23] group-hover:hidden"></div>
                  <div className="kalnia-regular  group-hover:bottom-auto  text-xl text-white z-20 w-full">
                    <div className="flex flex-col-reverse gap-2 justify-between group-hover:p-4 mb-10 group-hover:font-medium">
                      {item.title}
                      <div className="flex justify-end">
                        <img
                          src={arrow}
                          className="hidden group-hover:block cursor-pointer"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="absolute hidden opacity-0 group-hover:opacity-100 group-hover:block w-full z-20 poppins-regular text-base px-4 transition-all duration-500 h-[180px] overflow-y-scroll">
                      {item.text}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        {/* <div className="relative overflow-hidden transition-all duration-300">
          <img
            src={img1}
            className="w-full h-full object-cover hover:scale-110 transition-all duration-300"
          />
          <div className="w-full absolute bottom-0 px-2 py-2 bg-gradient-to-b from-white/5 to-[#212121]/70">
            <div className="w-[85px] h-[8px] bg-[#ff4a23]"></div>
            <h2 className="kalnia-regular w-[60%] text-xl text-white">
              Aluminum Geodesic Domes
            </h2>
          </div>
        </div>
        <div className="relative overflow-hidden transition-all duration-300">
          <img
            src={img2}
            className="w-full hover:scale-110 transition-all duration-300"
          />
          <div className="w-full absolute bottom-0 px-2 py-2 bg-gradient-to-b from-white/5 to-[#212121]/70">
            <div className="w-[85px] h-[8px] bg-[#ff4a23]"></div>
            <h2 className="kalnia-regular w-[60%] text-xl text-white">
              Aluminum Flat Covers
            </h2>
          </div>
        </div>
        <div className="relative overflow-hidden transition-all duration-300">
          <img
            src={img3}
            className="w-full hover:scale-110 transition-all duration-300"
          />
          <div className="w-full absolute bottom-0 px-2 py-2 bg-gradient-to-b from-white/5 to-[#212121]/70">
            <div className="w-[85px] h-[8px] bg-[#ff4a23]"></div>
            <h2 className="kalnia-regular w-[60%] text-xl text-white">
              Aluminum Floating Roof Systems
            </h2>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Card;
