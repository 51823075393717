import React, { useEffect, useState, useContext } from "react";
import { SlArrowDown } from "react-icons/sl";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/images/Logo.svg";
import { AuthContext } from "../context/AuthContext";
import PrimaryButton from "./common/PrimaryButton";

type LinkType = {
  name: string;
  path: string;
  href: string;
  target?: string;
  options?: LinkType[];
  active?: boolean;
};

const links: LinkType[] = [
  {
    name: "Home",
    path: "/",
    href: "#",
    active: false,
  },
  {
    name: "Products",
    path: "/products",
    href: "#",
    active: false,
  },
  {
    name: "Blog",
    path: "/blogs",
    href: "",
    active: false,
  },
  {
    name: "Resource",
    path: "/resource",
    href: "",
    active: false,
  },
  {
    name: "Request a Quote",
    path: "/request-a-quote",
    href: "",
    active: false,
  },
];

const Nav = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = React.useState(false);
  const [navLinks, setNavLinks] = useState<LinkType[]>(links);
  const [isContact, setIsContact] = useState(false);
  const [page, setPage] = useState("Home");
  const navigate = useNavigate();

  const authContext = useContext(AuthContext);

  const setActiveNav = (pageName: string) => {
    const newNavLinks = links.map((item) => {
      item.active = false;
      if (item.name === pageName) {
        item.active = true;
      }
      return item;
    });
    setNavLinks(newNavLinks);
  };

  useEffect(() => {
    links.forEach((item) => {
      if (item.path === location.pathname) {
        setActiveNav(item.name);
      }
      if (item.options && item.options.length > 0) {
        item.options.forEach((option) => {
          if (option.path === location.pathname) {
            setActiveNav(item.name);
          }
        });
      }
    });

    if (window.location.pathname === "/" && window.location.hash === "") {
      setActiveNav("Home");
    }

    if (window.location.pathname === "/contact-us") {
      setIsContact(true);
    } else {
      setIsContact(false);
    }

    setPage(window.location.pathname.split("/")[1]);
  }, [location]);

  return (
    <div>
      <div className="px-4 mx-auto max-w-7xl sm:px-6">
        <div className="relative sm:py-6">
          <nav
            className="relative flex items-center justify-between sm:h-10 md:justify-start md:space-x-10"
            aria-label="Global"
          >
            <div className="flex items-center flex-col w-full sm:w-auto">
              <div className="flex items-center justify-between w-full md:w-auto">
                <div className="flex w-full justify-between">
                  <Link to="/" onClick={() => setActiveNav("Home")}>
                    <span className="sr-only">Company Name</span>
                    <img
                      className="w-auto h-16 sm:h-20"
                      src={logo}
                      loading="lazy"
                      alt=""
                    />
                  </Link>
                  <div className="flex items-center -mr-2 md:hidden">
                    <button
                      className="inline-flex items-center justify-center p-2 text-gray-400 bg-gray-50 rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-50"
                      type="button"
                      onClick={() => setIsOpen(!isOpen)}
                      aria-expanded="false"
                    >
                      <span className="sr-only">Open main menu</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        aria-hidden="true"
                        className="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M4 6h16M4 12h16M4 18h16"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>

              <div
                className={`${
                  isOpen ? "flex" : "hidden"
                } lg:hidden items-center flex-col gap-4 font-montserrat w-full justify-center mb-4`}
                id="mobile-menu-2"
              >
                <div
                  className={`${
                    isOpen ? "flex" : "hidden"
                  } flex-col font-medium items-center lg:flex-row lg:flex lg:space-x-6 lg:mt-0`}
                >
                  {navLinks.map((link, index) => (
                    <div key={index * 10}>
                      {link.options && (
                        <div className="group relative py-2 lg:py-0">
                          <div className="flex items-center">
                            <span
                              className={`text-sm cursor-pointer ${
                                link.active ? "text-primary" : "text-black"
                              }`}
                            >
                              {link.name}
                            </span>
                            <SlArrowDown className="ml-1" fontSize={10} />
                          </div>
                          <div className="z-20 absolute -right-24 w-60 lg:-ml-12 -mt-1 origin-top-left bg-white divide-y divide-gray-100 rounded-md shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition duration-300">
                            <div className="py-1">
                              {link.options.map((option, index) => (
                                <Link
                                  to={option.path}
                                  key={index}
                                  onClick={() => {
                                    setActiveNav(link.name);
                                    setIsOpen(false);
                                    window.scrollTo(0, 0);
                                  }}
                                  className="block px-4 py-2 text-sm text-gray-700 bg-white hover:bg-gray-100"
                                >
                                  {option.name}
                                </Link>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}

                      {!link.options && (
                        <div className="relative z-10">
                          <Link
                            rel="noopener noreferrer"
                            to={link.path}
                            className={`text-sm bg-transparent z-10 ${
                              link.active ? "text-primary" : "text-black"
                            }`}
                            onClick={() => {
                              setActiveNav(link.name);
                              setIsOpen(false);
                              window.scrollTo(0, 0);
                            }}
                          >
                            {link.name}
                          </Link>
                        </div>
                      )}
                    </div>
                  ))}

                  <Link
                    rel="noopener noreferrer"
                    to="/contact-us"
                    className={"text-sm bg-transparent z-10"}
                    onClick={() => {
                      setActiveNav("Contact Us");
                      setIsOpen(false);
                      window.scrollTo(0, 0);
                    }}
                  >
                    Contact Us
                  </Link>
                </div>
                {page === "blogs" && authContext?.isAuthenticated ? (
                  <div className="inline-flex rounded-full mr-4">
                    <Link to="/create-blog" onClick={() => setActiveNav("")}>
                      <button
                        className={`${
                          isContact
                            ? "bg-primary text-white"
                            : "bg-white border border-primary text-black"
                        } rounded-full py-2 px-6 hover:-translate-y-1 transition-all duration-300`}
                      >
                        Create Blog
                      </button>
                    </Link>
                  </div>
                ) : (
                  <PrimaryButton
                    onClick={() => {
                      window.scrollTo(0, 0);
                      navigate("/login");
                    }}
                    className="hover:bg-white hover:!text-black border hover:border-primary !rounded-full mr-2"
                  >
                    Admin login
                  </PrimaryButton>
                )}
              </div>
            </div>
            <div className="hidden md:flex md:space-x-10 flex-wrap list-none">
              {navLinks.map((link, index) => (
                <div className="relative z-10 group" key={index}>
                  <Link
                    to={link.path}
                    key={link.name}
                    onClick={() => setActiveNav(link.name)}
                    className="text-base font-normal poppins-regular list-none hover:text-gray-900"
                  >
                    {link.name}
                  </Link>

                  <div className="group-hover:visible invisible -z-20 top-6 w-[60%] h-1 rounded-full bg-primary"></div>
                  {link.active && (
                    <div className="absolute -z-20 top-6 w-[60%] h-1 rounded-full bg-primary"></div>
                  )}
                </div>
              ))}
            </div>
            <div className="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
              {page === "blogs" && authContext?.isAuthenticated ? (
                <div className="inline-flex rounded-full mr-4">
                  <Link to="/create-blog" onClick={() => setActiveNav("")}>
                    <button
                      className={`${
                        isContact
                          ? "bg-primary text-white"
                          : "bg-white border border-primary text-black"
                      } rounded-full py-2 px-6 hover:-translate-y-1 transition-all duration-300`}
                    >
                      Create Blog
                    </button>
                  </Link>
                </div>
              ) : (
                <PrimaryButton
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate("/login");
                  }}
                  className="hover:bg-white hover:!text-black border hover:border-primary !rounded-full mr-2"
                >
                  Admin login
                </PrimaryButton>
              )}
              <div className="inline-flex rounded-full">
                <Link to="/contact-us" onClick={() => setActiveNav("")}>
                  <button
                    className={`${
                      isContact
                        ? "bg-primary text-white"
                        : "bg-white border border-primary text-black"
                    } rounded-full py-2 px-6 hover:-translate-y-1 transition-all duration-300`}
                  >
                    Contact Us
                  </button>
                </Link>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Nav;
