import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ContactFormValues } from "../../types/types";
import FormikField from "../common/FormikField";

const ContactForm = () => {
  const initialValues: ContactFormValues = {
    firstName: "",
    lastName: "",
    company: "",
    email: "",
    projectInfo: "",
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    company: Yup.string().required("Company is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    projectInfo: Yup.string().required("Please enter project details"),
  });

  const handleSubmit = async (values: ContactFormValues) => {
    console.log(values);
  };

  return (
    <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-xl sm:text-4xl font-bold mb-2 kalnia-regular text-white">
        Let's stay in touch
      </h1>

      <p className="text-white text-sm sm:text-base opacity-50 mb-8 poppins-regular">
        If you need help or would like to exchange facts about your storage tank
        needs, don't delay to contact us. We are capable of offering you the
        support you need.
      </p>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form className="space-y-6">
            <div className="flex gap-4 flex-col sm:flex-row">
              <FormikField name="firstName" placeholder="First Name" />
              <FormikField name="lastName" placeholder="Last Name" />
            </div>

            <div>
              <FormikField name="email" placeholder="Email" />
            </div>

            <div>
              <FormikField name="company" placeholder="Company Name" />
            </div>
            <div>
              <FormikField
                name="projectInfo"
                as="textarea"
                rows={6}
                placeholder="Project Details"
              />
            </div>
            <button
              type="submit"
              className="px-4 py-2 border border-white text-white rounded-full hover:bg-primary hover:border-transparent transition-all duration-300"
            >
              Send Message
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ContactForm;
